import DirectLink from '@common/components/DirectLink';
import { styled } from '@mui/material';
import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';

type BreadcrumbsProps = {
  to?: string;
  label: string;
  href?: string;
};

const Breadcrumb = styled(Typography, {
  name: 'BreadcrumbItem',
  slot: 'root',
  shouldForwardProp: prop => prop !== 'clickable'
})<{ clickable?: boolean }>(({ theme, clickable }) => ({
  ...(clickable && {
    ':hover': {
      textDecoration: 'underline'
    }
  })
}));

const Breadcrumbs = ({ data }: { data: BreadcrumbsProps[] }) => {
  return (
    <MuiBreadcrumbs aria-label="breadcrumb">
      {data.map((item: BreadcrumbsProps, index: number) => {
        return (
          <DirectLink key={index} to={item.to} href={item?.href}>
            <Breadcrumb
              variant="subtitle2"
              color={item.to || item?.href ? 'neutral.dark1' : 'neutral.dark3'}
              clickable={!!item.to || !!item?.href}
            >
              {item.label}
            </Breadcrumb>
          </DirectLink>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
