import { createSlice } from '@reduxjs/toolkit';

export const coreReducer = createSlice({
  name: 'core',
  initialState: {
    loading: false,
    bootstrapped: null
  },
  reducers: {
    showLoading: state => {
      state.loading = true;
    },
    hideLoading: state => {
      state.loading = false;
    },
    bootstrapping: state => {
      state.bootstrapped = 'run';
    },
    bootstrapped: state => {
      state.bootstrapped = 'done';
    }
  }
});
