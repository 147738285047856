import { Select, SelectProps, styled } from '@mui/material';
import classNames from 'classnames';
import React, { ForwardedRef } from 'react';
import Icon from '../Icon';

export const StyledSelect = styled(Select, { name: 'MTF-Select' })(
  ({ theme }) => ({
    backgroundColor: `${theme.palette.neutral.white} !important`,
    fontSize: '14px !important',
    '&.MuiInputBase-sizeSmall': {
      height: '48px'
    },
    '&.MuiInputBase-sizeMedium': {
      height: '56px'
    }
  })
);

export default React.forwardRef(
  ({ ...props }: SelectProps, ref: ForwardedRef<HTMLSpanElement>) => {
    return (
      <StyledSelect
        ref={ref}
        {...props}
        sx={{ backgroundColor: 'white' }}
        IconComponent={_props => {
          const rotate = _props.className.toString().includes('iconOpen');
          return (
            <div
              style={{
                position: 'absolute',
                cursor: 'pointer',
                pointerEvents: 'none',
                alignItems: 'center',
                right: 16,
                display: 'flex'
              }}
            >
              <Icon
                icon="icon-down"
                size="sm"
                className={classNames({ 'icon-flip-y': rotate })}
              />
            </div>
          );
        }}
      />
    );
  }
);
