import { Box, Button, CircularProgress, Divider } from '@mui/material';

export default function Footer(props: any) {
  const {
    closeDialog,
    cancelButtonLabel,
    submitButtonLabel,
    disabledSubmit = true,
    isSubmitting
  } = props;

  return (
    <>
      <Divider sx={{ mt: 3, mb: 2, ml: -4, mr: -4 }} />
      <Box
        sx={{ justifyContent: 'space-between' }}
        className="flex-center-rows"
      >
        {cancelButtonLabel ? (
          <Button onClick={closeDialog} variant="outlined" color="inherit">
            {cancelButtonLabel}
          </Button>
        ) : null}
        {submitButtonLabel ? (
          <Button
            disabled={disabledSubmit}
            type="submit"
            variant="contained"
            color="secondary"
            startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
          >
            {submitButtonLabel}
          </Button>
        ) : null}
      </Box>
    </>
  );
}
