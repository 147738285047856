import { listEntitySelector } from '@core/selector/entitySelector';
import { userInstance } from '@instance/userInstance';
import compactUrl from '@services/compactUrl';
import handleActionError from '@services/handleActionError';
import { put, select, takeEvery } from 'redux-saga/effects';

export function* entityFULLFIL(action: any): Record<string, any> {
  try {
    const { params, paging, module, apiUrl } = action.payload;
    const pagingData = yield select(state =>
      listEntitySelector(state, paging, module)
    );
    if (pagingData?.next_cursor === 'end' || pagingData.loading) return;

    const isLoadMore = !!pagingData?.next_cursor?.length;
    yield put({
      type: `${module}/INIT`,
      payload: { paging }
    });
    const response = yield userInstance.request({
      url: apiUrl,
      method: 'GET',
      params: {
        limit: 20,
        ...params,
        cursor: isLoadMore ? pagingData.next_cursor : undefined
      }
    });
    const data = response?.data?.data;
    yield put({
      type: `${module}/FULLFIL`,
      payload: { ...data, paging }
    });
  } catch (err) {
    yield handleActionError(err);
  }
}

export function* entityREFRESH(action: any): Record<string, any> {
  const { module } = action.payload;
  yield put({
    type: `${module}/refreshPaging`
  });
}

export function* fetchEntityDetail(action: any): Record<string, any> {
  try {
    const { params, module, apiUrl, setLoading } = action.payload;
    setLoading(true);
    const response = yield userInstance.request({
      url: compactUrl(apiUrl, { ...params }),
      method: 'GET'
    });
    const data = response?.data?.data;
    yield put({
      type: `${module}/fetchDetail`,
      payload: { data }
    });
    setLoading(false);
  } catch (err) {
    const { setLoading } = action.payload;
    setLoading(false);
    yield handleActionError(err);
  }
}

const entitySaga = [
  takeEvery('@entity/FULLFIL', entityFULLFIL),
  takeEvery('@entity/REFRESH', entityREFRESH),
  takeEvery('@entity/fetchItem', fetchEntityDetail)
];

export default entitySaga;
