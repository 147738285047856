import { styled } from '@mui/material';
export const FlightItemWrapper = styled('div', {
  name: 'FlightItemWrapper',
  shouldForwardProp: prop => prop !== 'deleting'
})<{ deleting?: boolean }>(({ theme, deleting }) => ({
  cursor: 'pointer',
  display: 'flex',
  backgroundColor: theme.palette.neutral.white,
  ':hover': {
    backgroundColor: theme.palette.neutral.light3,
    boxShadow: '0px 4px 4px 0px rgb(88,92,97, 0.4)',
    transition:
      'box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out',
    '.header': {
      backgroundColor: theme.palette.primary.light1,
      transition: 'background-color 0.2s ease-in-out'
    }
  },
  boxShadow: '0px 2px 4px 0px rgb(88,92,97, 0.24)',
  ...(deleting === true && {
    opacity: 0.3,
    pointerEvents: 'none',
    cursor: 'default'
  })
}));

export const FlightLine = styled('div', { name: 'FlightLine' })(
  ({ theme }) => ({
    width: '23px',
    borderBottom: `1px solid ${theme.palette.neutral.light1}`
  })
);
