import { Box, Typography } from '@mui/material';

export default function EmptyFlightTable() {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '380px',
        justifyContent: 'center',
        borderRadius: '8px'
      }}
      className="flex-center-columns"
    >
      <Box>
        <img src="/images/empty_flight_list.svg" alt="" />
      </Box>
      <Typography
        sx={{ mt: 1 }}
        component="div"
        variant="body2"
        color="neutral.dark1"
      >
        <i>No flights found</i>
      </Typography>
    </Box>
  );
}
