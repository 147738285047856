import { Box, styled } from '@mui/material';

export const Wrapper = styled('div', { name: 'ExpiredWrapper' })(
  ({ theme }) => ({
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ul: {
      margin: 0
    },
    li: {
      marginBottom: theme.spacing(1),
      '.highlight': {
        fontWeight: 700,
        color: theme.palette.primary.dark1,
        textDecoration: 'none'
      }
    }
  })
);

export const Banner = styled(Box, { name: 'Banner' })(({ theme }) => ({
  height: '176px',
  backgroundImage: `url("/images/background-mobile.png")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  justifyContent: 'center'
}));

export const Title = styled('div', { name: 'Title' })(({ theme }) => ({
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '22.5px'
}));
