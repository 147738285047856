import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { userInfoSelector } from '@user/selector/userInfoSelector';
import { get } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function TimezoneToggle({
  size = 'medium'
}: {
  size?: 'small' | 'medium' | 'large';
}) {
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoSelector);

  const timezoneConfig = get(
    userInfo,
    'timezone_preference',
    'local'
  );

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (!newValue) return;
    dispatch({
      type: '@user/updateInfo',
      payload: { timezone_preference: newValue }
    });
  };

  return (
    <ToggleButtonGroup
      value={timezoneConfig}
      exclusive
      onChange={handleChange}
      fullWidth
      size={size}
    >
      <ToggleButton disableRipple value="local">
        <Typography variant="body2">Local</Typography>
      </ToggleButton>
      <ToggleButton disableRipple value="utc">
        <Typography variant="body2">UTC</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
