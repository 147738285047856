import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { useField } from 'formik';

function DatePickerField({ config, name }) {
  const { label, component, variant, disabled, ...restConfig } = config;

  const [field, meta, { setValue }] = useField(name ?? 'date');

  const handleDateChange = (date: Date, value: string) => {
    setValue(date ? dayjs(date).format('DD/MM/YYYY') : undefined);
  };

  const haveError = !!meta.error;

  return (
    <>
      <DatePicker
        value={field.value ? dayjs(field.value, 'DD/MM/YYYY') : null}
        onChange={handleDateChange}
        label={label}
        views={['year', 'month', 'day']}
        format="DD/MM/YYYY"
        clearable
        disabled={disabled}
        {...restConfig}
        slotProps={{
          textField: { variant: 'filled', size: 'small' }
        }}
        renderInput={params => <TextField {...params} fullWidth />}
      />
      {haveError ? <div className="errorMessage">{meta.error}</div> : null}
    </>
  );
}

export default DatePickerField;
