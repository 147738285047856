import { LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';

const THRESSHOLD = 500;

export default function FakeLoadingProgressBar({ done }: { done: boolean }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (done) {
        setProgress(100);
        return;
      }
      setProgress(oldProgress => {
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, THRESSHOLD);

    return () => {
      clearInterval(timer);
    };
  }, [done]);
  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      sx={{ height: 8, borderRadius: 0 }}
      color="primary"
    />
  );
}
