import {
  AllergiesForm,
  AutoCompleteField,
  SelectForm,
  TextFieldForm,
  DatePickerField
} from '@common/formElement';
import { Avatar, Box, Grid, InputAdornment, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useFetchDetail from 'src/hooks/useFetchDetail';
import Footer from '../ActionFooter';
import { Wrapper } from './styled';
import { EditClientSchema } from '@client/helper/schema';

export default function EditClientDialog(props: any) {
  const {
    closeDialog,
    cancelButtonLabel,
    submitButtonLabel,
    userId,
    setForceOpen
  } = props;
  const [values, setValues] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (value: any, formik: FormikHelpers<any>) => {
    dispatch({
      type: '@client/updateClient',
      payload: { data: value, userId },
      meta: { setSubmitting, closeDialog }
    });
  };

  const [clientData] = useFetchDetail(
    'client',
    '/clients/:id',
    parseInt(userId)
  );

  useEffect(() => {
    setForceOpen(isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  const initialValues = {
    firstName: clientData.firstName,
    lastName: clientData.lastName,
    allergies: clientData.allergies,
    phone_country_code: clientData.phone_country_code,
    date_of_birth: clientData.date_of_birth,
    email: clientData.email,
    mobile: clientData.mobile,
    passport_expiry_date: clientData.passport_expiry_date,
    passport_number: clientData.passport_number,
    pax_weight: clientData.pax_weight,
    sex: clientData.sex,
    nationality: clientData.nationality,
    passport_issue_date: clientData.passport_issue_date,
    place_of_birth: clientData.place_of_birth
  };

  const flatInitialValues = {
    ...initialValues,
    phone_country_code: clientData.phone_country_code?.value,
    allergies: clientData.allergies?.map(item => item.id),
    nationality: clientData.nationality?.value
  };

  const isDirty = !isEqual(values, flatInitialValues);

  return (
    <Wrapper>
      <Box sx={{ mt: 1 }}>
        <Box className="flex-center-columns" sx={{ mb: 3 }}>
          <Avatar
            className="fs-exclude"
            sx={{
              width: 96,
              height: 96
            }}
            src={clientData?.onfido_image}
          />
        </Box>
        <Typography className="MontserratH4" component="div" sx={{ mb: 2 }}>
          Account info
        </Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={EditClientSchema}
          validateOnMount
          innerRef={formikActions =>
            formikActions ? setValues(formikActions.values) : setValues({})
          }
        >
          {({ initialValues, isValid }) => (
            <Form>
              <Box sx={{ mb: 4 }}>
                <Grid
                  container
                  columnSpacing={{ xs: 1, sm: 2 }}
                  rowSpacing={{ xs: 1, sm: 2 }}
                >
                  <Grid item xs={12} sm={6}>
                    <TextFieldForm
                      name="firstName"
                      config={{
                        label: 'First name',
                        fullWidth: true,
                        required: true
                      }}
                      initialValues={initialValues}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldForm
                      name="lastName"
                      config={{
                        label: 'Last name',
                        fullWidth: true,
                        required: true
                      }}
                      initialValues={initialValues}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <AutoCompleteField
                      name="phone_country_code"
                      config={{
                        label: 'Country code',
                        fullWidth: true,
                        required: true,
                        searchable: false,
                        uniqueKey: 'countryCodeList',
                        search_endpoint: '/country-codes',
                        noOptionsText: 'No code found',
                        clearIcon: false
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextFieldForm
                      name="mobile"
                      config={{
                        label: 'Phone number',
                        fullWidth: true,
                        required: true
                      }}
                      initialValues={initialValues}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldForm
                      name="email"
                      config={{
                        label: 'Email address',
                        fullWidth: true,
                        required: true,
                        disabled: true
                      }}
                      initialValues={initialValues}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <DatePickerField
                      name="date_of_birth"
                      config={{
                        label: 'Date of birth',
                        fullWidth: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextFieldForm
                      name="place_of_birth"
                      config={{
                        label: 'Place of birth',
                        fullWidth: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AutoCompleteField
                      name="nationality"
                      config={{
                        label: 'Nationality',
                        fullWidth: true,
                        searchable: false,
                        search_endpoint: '/nationalities',
                        uniqueKey: 'nationalList',
                        noOptionsText: 'No nationality found',
                        clearIcon: false
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldForm
                      name="passport_number"
                      config={{
                        label: 'Passport number',
                        fullWidth: true
                      }}
                      initialValues={initialValues}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <DatePickerField
                      name="passport_issue_date"
                      config={{
                        label: 'Valid from',
                        fullWidth: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <DatePickerField
                      name="passport_expiry_date"
                      config={{
                        label: 'Valid until',
                        fullWidth: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectForm
                      name="sex"
                      config={{
                        label: 'Gender',
                        fullWidth: true,
                        required: true,
                        options: [
                          { value: 'F', label: 'Female' },
                          { value: 'M', label: 'Male' }
                        ]
                      }}
                      initialValues={initialValues}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldForm
                      name="pax_weight"
                      config={{
                        label: 'Weight',
                        fullWidth: true,
                        maxLength: 3,
                        endAdornment: (
                          <InputAdornment position="end">kg</InputAdornment>
                        )
                      }}
                      initialValues={initialValues}
                    />
                  </Grid>
                </Grid>
                <Typography
                  className="MontserratH4"
                  component="div"
                  sx={{ mb: 2, mt: 3 }}
                >
                  Allergies
                </Typography>

                <Typography variant="body2" component="div" sx={{ mb: 2 }}>
                  You may be offered catering aboard your flight based on your
                  allergy information
                </Typography>
                <AllergiesForm
                  name="allergies"
                  config={{
                    label: 'Allergies',
                    fullWidth: true
                  }}
                />
              </Box>

              <Footer
                cancelButtonLabel={cancelButtonLabel}
                submitButtonLabel={submitButtonLabel}
                closeDialog={closeDialog}
                disabledSubmit={!isValid || !isDirty || isSubmitting}
                isSubmitting={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </Wrapper>
  );
}
