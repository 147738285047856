import { Box, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FlightLine } from './styled';
import Icon from '@common/components/Icon';
import useAirportCode from 'src/hooks/useAirportCode';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useTimezone from 'src/hooks/useTimezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export default function Leg({ item }: { item: Record<string, any> }) {
  const {
    depart_airport,
    depart_date,
    arrive_airport,
    arrive_date,
    paxTotal,
    paxAmount,
    cateringAmount,
    cateringTotal,
    hotelComplete,
    transportComplete
  } = item;

  const airportCodeConfig = useAirportCode();
  const timezone = useTimezone();
  const departDateTime = dayjs
    .utc(depart_date, 'DD/MM/YYYY hh:mm A')
    .tz(depart_airport.tz_tz, timezone === 'utc');

  const arriveDateTime = dayjs
    .utc(arrive_date, 'DD/MM/YYYY hh:mm A')
    .tz(arrive_airport.tz_tz, timezone === 'utc');

  const returnColor = (
    amount: number,
    total: number,
    primaryCondition?: boolean
  ) => {
    if (amount > 0 || primaryCondition) {
      if (amount === total || primaryCondition) {
        return 'positive.dark1';
      } else {
        return 'warning.dark1';
      }
    } else {
      return 'neutral.dark2';
    }
  };
  return (
    <Box
      className="flex-space-between-row"
      sx={{
        width: '100%',
        p: 2,
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '35%',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: 1,
          borderRight: '1px solid #CCD4DC'
        }}
      >
        <Box className="flex-column" sx={{ alignItems: 'flex-end', flex: 1 }}>
          <Tooltip title={depart_airport.name} arrow>
            <Typography
              variant="head4Mons"
              component="div"
              sx={{ mb: 1 }}
              color="neutral.dark3"
            >
              {depart_airport?.[airportCodeConfig]}
            </Typography>
          </Tooltip>

          <Typography variant="body1" color="neutral.dark3">
            {depart_date ? departDateTime.format('HH:mm') : 'TBD'}
          </Typography>
          <Typography variant="caption" color="neutral.dark1">
            {depart_date ? departDateTime.format('YYYY-MM-DD') : 'TBD'}
          </Typography>
        </Box>
        <Box className="flex-center-rows" sx={{ gap: 1 }}>
          <FlightLine />
          <Icon
            icon="icon-airplane-above-fill icon-rotate-90"
            sx={{ color: 'neutral.main' }}
          />
          <FlightLine />
        </Box>

        <Box className="flex-column" sx={{ alignItems: 'flex-start', flex: 1 }}>
          <Tooltip title={arrive_airport.name} arrow>
            <Typography
              variant="head4Mons"
              component="div"
              sx={{ mb: 1 }}
              color="neutral.dark3"
            >
              {arrive_airport?.[airportCodeConfig]}
            </Typography>
          </Tooltip>

          <Typography variant="body1" color="neutral.dark3">
            {arrive_date ? arriveDateTime.format('HH:mm') : 'TBD'}
          </Typography>
          <Typography variant="caption" color="neutral.dark1">
            {arrive_date ? arriveDateTime.format('YYYY-MM-DD') : 'TBD'}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          px: 2,
          flex: 1,
          justifyContent: 'space-around'
        }}
      >
        <Box className="flex-center-columns" sx={{ minWidth: '80px' }}>
          <Icon
            icon="icon-passport-bold"
            sx={{ color: returnColor(paxAmount, paxTotal), mb: 0.5 }}
          />
          <Typography variant="caption" color="neutral.dark3">
            {`${paxAmount}/${paxTotal}`}
          </Typography>
          <Typography variant="caption" color="neutral.dark2">
            Passport
          </Typography>
        </Box>
        <Box className="flex-center-columns" sx={{ minWidth: '80px' }}>
          <Icon
            icon="icon-catering-2"
            sx={{ color: returnColor(cateringAmount, cateringTotal), mb: 0.5 }}
          />
          <Typography variant="caption" color="neutral.dark3">
            {`${cateringAmount}/${cateringTotal}`}
          </Typography>
          <Typography variant="caption" color="neutral.dark2">
            Catering
          </Typography>
        </Box>
        <Box className="flex-center-columns" sx={{ minWidth: '80px' }}>
          <Icon
            icon="icon-Hotel"
            sx={{
              color: returnColor(null, null, hotelComplete),
              mb: 0.5
            }}
          />
          <Typography variant="caption" color="neutral.dark3">
            {hotelComplete ? 'Data added' : 'No data'}
          </Typography>
          <Typography variant="caption" color="neutral.dark2">
            Hotels
          </Typography>
        </Box>
        <Box className="flex-center-columns" sx={{ minWidth: '80px' }}>
          <Icon
            icon="icon-car-fill"
            sx={{
              color: returnColor(null, null, transportComplete),
              mb: 0.5
            }}
          />
          <Typography variant="caption" color="neutral.dark3">
            {transportComplete ? 'Data added' : 'No data'}
          </Typography>
          <Typography variant="caption" color="neutral.dark2">
            Transport
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
