import { get } from 'lodash';
import { createSelector } from 'reselect';

const getUserInfo = (state: any) => state.user;

export const userInfoSelector = createSelector(
  getUserInfo,
  data => data.userInfo
);

export const userLevelSelector = createSelector(getUserInfo, data =>
  get(data, 'userInfo.customer_type_id', null)
);
