import { put, takeEvery } from 'redux-saga/effects';

export function* showLoading() {
  yield put({ type: 'core/showLoading' });
}

export function* hideLoading() {
  yield put({ type: 'core/hideLoading' });
}

const globalLoading = [
  takeEvery('@loading/show', showLoading),
  takeEvery('@loading/hide', hideLoading)
];

export default globalLoading;
