import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { produce } from 'immer';
import { get, unset } from 'lodash';

export const clientsReducer = createSlice({
  name: 'client',
  initialState: {
    client: {},
    paging: {}
  },
  reducers: {
    INIT: (state, action) => {
      const { paging } = action.payload;
      state.paging[paging] = {
        ...state.paging[paging],
        loading: true
      };
    },
    FULLFIL: (state, action) => {
      const {
        data: clientList,
        meta: { next_cursor },
        paging
      } = action.payload;
      // GET CURRENT PAGING DATA
      const pagingData: number[] = get(state, ['paging', paging, 'data'], []);

      const fullfilData: Record<any, string> = {};
      clientList.forEach((item: any) => {
        // MAP ARRAY DATA TO OBJECT ENTITY
        fullfilData[item.id] = item;

        // PUSH NEW ITEM TO PAGING DATA
        pagingData.push(parseInt(item.id));
      });

      //UPDATE NEW OBJECT ENTITY
      state.client = {
        ...get(state, 'client', {}),
        ...fullfilData
      };

      //UPDATE PAGING DATA
      state.paging[paging] = {
        data: pagingData,
        next_cursor: next_cursor ? next_cursor : 'end',
        refreshing: false
      };
    },
    fetchDetail: (state, action) => {
      const { data: clientData } = action.payload;
      const newState = {
        ...get(state, 'client', {}),
        [clientData.id]: clientData
      };
      state.client = newState;
    },
    deleteEntity: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      const newState = produce(state.client, draft => {
        unset(draft, id);
      });
      state.client = newState;
    },
    updateEntity: (state, action: PayloadAction<Record<string, any>>) => {
      const { id, data } = action.payload;
      const newState = produce(state.client, draft => {
        draft[id] = { ...draft[id], ...data };
      });
      state.client = newState;
    },
    refreshPaging: (state, action: PayloadAction<Record<string, any>>) => {
      const pagingData = state.paging;
      Object.keys(pagingData).forEach(key => {
        pagingData[key] = {
          refreshing: true,
          data: [],
          next_cursor: null
        };
      });
    }
  }
});
