import { Pagination } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

export const PaginationBar = (props: any) => {
  const [, setSearchParams] = useSearchParams({});

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setSearchParams(prevParams => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        page: `${value}`
      });
    });
  };
  return <Pagination {...props} onChange={handleChange} />;
};
