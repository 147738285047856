import { FLIGHT_STATUS } from '@constant/flight_status';
import { isArray } from 'lodash';

export function shortenName(name: string): string {
  if (!name) return 'NA';

  const cs = name
    .trim()
    .split(/\s+/g)
    .splice(0, 2)
    .map(x => String.fromCodePoint(x.codePointAt(0)).toUpperCase());

  if (name.split(/\s+/g).length === 1) {
    return name.slice(0, 2).toUpperCase();
  }

  return 1 < name.length
    ? cs.join('')
    : Array.from(cs)
        .splice(0, 2)
        .map(x => x.toUpperCase())
        .join('');
}

function hashCode(str: string) {
  let hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

export function formatPhone(str: string) {
  // PHONE UK FORMAT: +XX XXXX XXXXX
  let reg = new RegExp(String.raw`(\d{4})(\d{${str.length - 4}})`);

  return str.replace(reg, '$1 $2');
}

export function pickColor(str: string) {
  return `linear-gradient(to left bottom, hsl(${
    hashCode(str) % 360
  }, 100%, 70%) 10%,  hsl(${hashCode(str) % 360}, 100%, 35%) 70%)`;
}

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function joinCatering(cateringList: Record<string, any>[]) {
  if (!isArray(cateringList) || cateringList?.length < 1) {
    return null;
  }
  if (cateringList?.length === 1) {
    return cateringList[0].name;
  }
  return cateringList
    ?.slice(0, -1)
    ?.map(item => {
      return item.name;
    })
    .join(', ')
    .concat(' and ')
    .concat(cateringList.at(-1).name);
}

export function flatStringParams(params: Record<string, any>) {
  const pagingParam = new URLSearchParams(params);
  pagingParam.sort();
  return pagingParam.toString().length ? `?${pagingParam.toString()}` : '';
}

export const tooltipPassportStatusData = (value: number) => {
  switch (value) {
    case 1: {
      return {
        text: 'Awaiting client acceptance',
        icon: 'icon-mail-pending',
        color: 'warning'
      };
    }
    case 2: {
      return {
        text: 'Verifying passport',
        icon: 'icon-passport-pending-bold',
        color: 'primary'
      };
    }
    case 3: {
      return {
        text: 'Passport verified',
        icon: 'icon-passport-bold',
        color: 'positive'
      };
    }
    case 4: {
      return {
        text: 'Passport rejected',
        icon: 'icon-passport-fail-bold',
        color: 'accent'
      };
    }
  }
};

export const getName = ({
  firstName,
  lastName,
  email
}: Record<string, any>) => {
  if (!firstName && !!lastName && !email) return null;
  return firstName || lastName
    ? `${firstName ?? ''} ${lastName ?? ''}`.trim()
    : email;
};

export const tooltipFlightStatusData = (value: number) => {
  switch (value) {
    case FLIGHT_STATUS.ENQUIRY: {
      return {
        text: 'Enquiry',
        color: 'accent.light3',
        border: 'danger.main',
        textColor: 'danger.main'
      };
    }
    case FLIGHT_STATUS.AC_SIGNED: {
      return {
        text: 'A/C signed for, client to be invoiced',
        color: 'neutral.light3',
        border: 'neutral.dark1',
        textColor: 'neutral.dark2'
      };
    }
    case FLIGHT_STATUS.INVOICE_SENT: {
      return {
        text: 'Invoice sent',
        color: 'warning.light2',
        border: 'warning.dark2',
        textColor: 'warning.dark2'
      };
    }
    case FLIGHT_STATUS.OPERATOR_PAID: {
      return {
        text: 'Operator paid',
        color: 'primary.light2',
        border: 'primary.dark1',
        textColor: 'primary.dark1'
      };
    }
    case FLIGHT_STATUS.PAYMENT_RECEIVED: {
      return {
        text: 'Payment received',
        color: 'positive.light2',
        border: 'positive.dark1',
        textColor: 'positive.dark2'
      };
    }
  }
};
