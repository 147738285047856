import { Checkbox, FormControl } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useField } from 'formik';
import { camelCase } from 'lodash';
import { styled } from '@mui/material/styles';

export const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 18,
  height: 18,
  backgroundColor: theme.palette.neutral.white,
  boxShadow: `inset 0px 0px 0px 1px ${theme.palette.neutral.light2}`,
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5'
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)'
  }
}));

export const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark1,
  boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.light1}`,
  '&::before': {
    display: 'block',
    width: 18,
    height: 18,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""'
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3'
  }
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  alignItems: 'flex-start',
  gap: theme.spacing(1.5),
  margin: 0,
  '.MuiCheckbox-sizeSmall': {
    padding: '3px 0px'
  }
}));

const CheckboxField = (props: any) => {
  const { config, name } = props;
  const [field, , helpers] = useField(name ?? 'CheckboxField');

  const {
    label,
    checkedValue = 1,
    uncheckedValue = 0,
    margin,
    size,
    fullWidth
  } = config;

  return (
    <FormControl
      margin={margin}
      fullWidth={fullWidth}
      data-testid={camelCase(`field ${name}`)}
    >
      <StyledFormControlLabel
        label={label}
        checked={field.value === checkedValue}
        onChange={(_, checked) =>
          helpers.setValue(checked ? checkedValue : uncheckedValue)
        }
        sx={{ alignItems: 'flex-start' }}
        control={
          <Checkbox
            size={size}
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            data-testid={camelCase(`input ${name}`)}
            inputProps={{ 'aria-label': label }}
          />
        }
      />
    </FormControl>
  );
};

export default CheckboxField;
