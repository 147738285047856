import React from 'react';

export interface ErrorFallbackProps {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

export default function ErrorFallback({
  error,
  errorInfo
}: ErrorFallbackProps) {
  return (
    <div data-testid="errorBoundary">
      <h2>Something went wrong. Please try again later</h2>
    </div>
  );
}
