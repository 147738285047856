import { ONBOARDING_STEP, useRegister } from '@context/RegisterContext';
import { Box, Divider } from '@mui/material';
import { useEffect, useMemo, useRef } from 'react';
import MobileStep from '../common/MobileStep';
import {
  Allergies,
  ConfirmDetail,
  SecureAccount,
  VerifyIdentity,
  WelcomeOnboard
} from '../components/_registerComponent';
import { SubTitle, Title } from '../components/_styled';
import { Wrapper } from './styled';

const RegisterPage = () => {
  const { step } = useRegister();

  const CurrentStepPage = () => {
    switch (step) {
      case 1:
        return <ConfirmDetail />;
      case 2:
        return null;
      case 3:
        return <Allergies />;
      case 4:
      case 5:
        return <SecureAccount />;
      default:
        return <WelcomeOnboard />;
    }
  };
  const SubTitleLabel = useMemo(() => {
    switch (step) {
      case ONBOARDING_STEP.CONFIRM_DETAIL:
        return 'Confirm details & Data retention duration';
      case ONBOARDING_STEP.VERIFY_IDENTITY:
        return 'Verify your identity';
      case ONBOARDING_STEP.ALLERGIES:
        return '';
      case ONBOARDING_STEP.SECURE:
      case ONBOARDING_STEP.DONE:
        return 'Secure your account';
      default:
        return '';
    }
  }, [step]);

  const registerWrapperRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => {
      if (!registerWrapperRef.current) return;
      registerWrapperRef.current.scrollTop = 0;
    }, 30);
  }, [step]);

  return (
    <Wrapper ref={registerWrapperRef} step={step}>
      {step !== 0 ? (
        <>
          <Title>Register</Title>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <SubTitle className="flex-center-rows" sx={{ gap: 2 }}>
            <MobileStep step={step} />
            {step === ONBOARDING_STEP.ALLERGIES ? (
              <span>
                Add your allergies <span className="note">{`(optional)`}</span>
              </span>
            ) : (
              SubTitleLabel
            )}
          </SubTitle>
        </>
      ) : null}
      <Box sx={{ flex: 1, display: step === 2 ? 'block' : 'none' }}>
        <VerifyIdentity scrollRef={registerWrapperRef} />
      </Box>
      <Box sx={{ flex: 1, display: step === 2 ? 'none' : 'block' }}>
        <CurrentStepPage />
      </Box>
    </Wrapper>
  );
};

export default RegisterPage;
