import BootstrappingPage from '@common/components/BootstrappingPage';
import { bootstrappedSelector } from '@core/selector';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function AuthorProvider({ children }) {
  const dispatch = useDispatch();
  const bootstrapped = useSelector(bootstrappedSelector);

  useEffect(() => {
    dispatch({
      type: '@bootstrap'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (bootstrapped === 'run') return <BootstrappingPage />;
  if (bootstrapped === 'done') return children;
}
