import { styled } from '@mui/material';
import { STATUS } from '../common/RegisterNavigate';

export const NavigateWrapper = styled('div', {
  name: 'NavigateWrapper',
  shouldForwardProp: prop => prop !== 'step'
})<{ step?: number }>(({ theme, step }) => ({
  height: '100%',
  width: '50vw',
  position: 'fixed',
  top: '0',
  left: '0',
  backgroundImage: `url("/images/background.svg")`,
  backgroundSize: 'cover',
  backgroundPosition: 'bottom',
  overflow: 'hidden',
  display: 'flex',
  padding: `${theme.spacing(11)} ${theme.spacing(6)}`,
  gap: theme.spacing(3),
  flexDirection: 'column',
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.neutral.dark3,
    transition: 'opacity 0.3s ease-in-out',
    opacity: 0
  },
  div: {
    visibility: 'hidden',
    display: 'none'
  },
  transition: 'width 0.3s ease-in-out',
  '.logo-southend': {
    height: '72px',
    width: '396px'
  },
  ...(step === 0 && {
    alignItems: 'center',
    justifyContent: 'center'
  }),
  ...(step !== 0 && {
    '.logo-southend': {
      display: 'none'
    },
    width: '400px',
    div: {
      visibility: 'visible',
      display: 'flex'
    },
    transition: 'width 0.3s ease-in-out',
    '::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.palette.neutral.dark3,
      transition: 'opacity 0.3s ease-in-out',
      opacity: '0.6 !important'
    }
  }),
  [theme.breakpoints.down('lg')]: {
    backgroundImage: `url("/images/background-mobile.png")`,
    '.logo-southend': {
      display: 'block',
      opacity: 0
    },
    backgroundPosition: 'center',
    width: '100vw',
    left: '0',
    height: '150px',
    maxHeight: '150px',
    top: '0',
    position: 'static',
    div: {
      display: 'none'
    },
    '::before': {
      display: 'none'
    },
    justifyContent: 'center',
    ...(step === 0 && {
      '.logo-southend': {
        opacity: 1
      },
      alignItems: 'flex-start'
    }),
    transition: 'padding 0.3s ease-in-out, max-height 0.3s ease-in-out',
    ...(step !== 0 && {
      maxHeight: '0px',
      padding: '0px !important',
      transition: 'padding 0.3s ease-in-out, max-height 0.3s ease-in-out'
    })
  },
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`
  },
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    '.logo-southend': {
      width: 'min(calc(100% - 40px), 350px)'
    }
  }
}));

export const StepItem = styled('div', {
  name: 'StepItem',
  shouldForwardProp: prop => prop !== 'status'
})<{ status?: number }>(({ theme, status }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  zIndex: 1,
  span: {
    fontWeight: 600
  },
  ...(status === STATUS.NOT_PASSED && {
    opacity: 0.4
  })
}));

export const CircleRound = styled('div', {
  name: 'StepItem',
  shouldForwardProp: prop => prop !== 'status'
})<{ status?: number }>(({ theme, status }) => ({
  height: '20px',
  width: '20px',
  margin: '2px',
  border: '1px solid white',
  borderRadius: '100%',
  ...(status === STATUS.CURRENT && {})
}));
