import { BaseDialog, propsDialogComponent } from '@common/dialog/BaseDialog';
import ConfirmDialog from '@common/dialog/ConfirmDialog';
import React, { Component } from 'react';

export class DialogProvider extends Component {
  state: { dialogs: propsDialogComponent[] } = {
    dialogs: [] // includes multiple models
  };

  totalIndex = 0;

  open = ({ ...args }) => {
    const dialog = { ...args };
    let { dialogs } = this.state;

    const listComponentDialog = dialogs.map(item => {
      return item.component;
    });

    if (listComponentDialog.includes(dialog.component)) return;

    this.totalIndex++;

    dialog.isVisible = true;
    dialog.id = this.totalIndex;

    if (!dialog.ref) {
      dialog.ref = React.createRef();
    }

    const result = new Promise(resolve => {
      dialogs.push({
        ...dialog,
        resolve
      });
    });
    this.setState({ dialogs });

    return result;
  };

  confirm = async (params: any) => {
    const choose = await this.open({
      component: ConfirmDialog,
      props: params?.props,
      ...params
    });
    return !!choose;
  };

  close = (props: any, id: any) => {
    if (props === 'all') {
      this.setState({ dialogs: [] });
      return;
    }
    if (props?.component) {
      const componentName = props?.component;
      let { dialogs } = this.state;
      let newDialogs = dialogs.filter(item => item.component !== componentName);
      this.setState({ dialogs: newDialogs });
      return;
    }
    let { dialogs } = this.state;
    let newDialogs = dialogs.filter(item => item.id !== id);
    this.setState({ dialogs: newDialogs });
  };

  updateProps = ({ ...props }, index: number) => {
    const { dialogs } = this.state;
    dialogs[index].props = { ...dialogs[index].props, ...props };
    this.setState({ dialogs });
  };

  render() {
    const { dialogs } = this.state;

    return dialogs.map((dialog, index) => (
      <BaseDialog
        key={dialog.id + '' + index}
        closeDialog={props => this.close(props, dialog.id)}
        {...dialog}
      />
    ));
  }
}
