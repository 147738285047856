import GlobalProvider from '@context/GlobalContext';
import ThemeProvider from '@utils/theme/ThemeProvider';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from './store';
import 'dayjs/locale/en-gb';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const persistor = persistStore(store);
root.render(
  <GlobalProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
      {/* <LocaleProvider> */}
      <ReduxProvider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeProvider>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              autoHideDuration={2000}
              maxSnack={3}
            >
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </PersistGate>
      </ReduxProvider>
      {/* </LocaleProvider> */}
    </LocalizationProvider>
  </GlobalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
