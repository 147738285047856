import EmptyClientTable from './EmptyClientTable';
import ClientTableItem from './Item';
import ClientTableItemSkeleton from './Item/LoadingSkeleton';
import { ClientListWrapper } from './styled';

export default function ClientTableBlock({ loading, data }) {
  return (
    <ClientListWrapper>
      {loading && !data?.next_cursor ? (
        Array(10)
          .fill(0)
          .map((_, index) => <ClientTableItemSkeleton key={index} />)
      ) : data?.data.length ? (
        data?.data.map((item: number, index: number) => (
          <ClientTableItem key={index} id={item} />
        ))
      ) : (
        <EmptyClientTable />
      )}
      {data.next_cursor && loading
        ? Array(5)
            .fill(0)
            .map((_, index) => <ClientTableItemSkeleton key={index} />)
        : null}
    </ClientListWrapper>
  );
}
