import { Box, styled } from '@mui/material';

export const Wrapper = styled('div', { name: 'AddClientDialogWrapper' })(
  ({ theme }) => ({
    minWidth: '800px',
    overflow: 'hidden',
    padding: '24px 32px'
  })
);

export const Instruction = styled(Box, {
  name: 'Instruction',
  shouldForwardProp: prop => prop !== 'open'
})<{ open?: boolean }>(({ theme, open }) => ({
  marginTop: '4px',
  overflow: 'hidden',
  maxHeight: '0px',
  transition: 'all 0.5s ease-in-out',
  ul: {
    margin: 0
  },
  ...(open && {
    maxHeight: '500px',
    transition: 'all 0.5s ease-in-out'
  })
}));
