import axios from 'axios';
import Cookies from 'js-cookie';
import store from '../store';

const computeToken = () => {
  const token = Cookies.get('token');

  if (token) return `Bearer ${token ?? ''}`;

  return undefined;
};

export const userInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 200000
});

export const guessInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 200000
});

userInstance.interceptors.request.use(
  config => {
    const token = computeToken();
    if (token && config.headers.Authorization === undefined) {
      config.headers.Authorization = token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

userInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { dispatch } = store;
    if (error?.response?.status === 401) {
      dispatch({ type: '@logout', payload: { isExpired: true } });
    }

    throw error;
  }
);
