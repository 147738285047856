import { userInstance } from '@instance/userInstance';
import handleActionError from '@services/handleActionError';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import router from '@utils/route/router';
import Cookies from 'js-cookie';
import { get } from 'lodash';
import { put, takeEvery } from 'redux-saga/effects';

export function* getUserInfo(): Record<string, any> {
  try {
    if (!Cookies.get('token')) {
      yield put({ type: '@logout' });
      return;
    }

    yield put({ type: 'core/bootstrapping' });

    const response = yield userInstance.request({
      url: '/profile',
      method: 'GET'
    });

    const userInfo = response?.data?.data;

    try {
      //@ts-ignore
      FS('setIdentity', {
        uid: userInfo.user_info.id
      });
    } catch (err) {}

    // if (!getMenu(userInfo?.customer_type_id).length) {
    //   yield put({ type: 'user/setUserInfo', payload: null });
    //   router.window.location = `${process.env.REACT_APP_PHP_BASE_URL}`;
    //   return;
    // }
    yield put({ type: 'user/setUserInfo', payload: userInfo });

    yield put({ type: 'core/bootstrapped' });
  } catch (err) {
    yield put({ type: '@logout' });
    yield handleActionError(err);
  }
}

export function* updateUserInfo(action: any): Record<string, any> {
  try {
    yield put({ type: 'user/updateInfo', payload: action.payload });
    yield userInstance.request({
      url: '/profile',
      method: 'PUT',
      data: {
        ...action.payload
      }
    });
  } catch (err) {
    yield handleActionError(err);
  }
}

export function* logOut(action: any): Record<string, any> {
  try {
    Cookies.remove('PHPSESSID');
    Cookies.remove('token');
    yield put({ type: 'USER_LOGOUT' });
    const isExpired = get(action, ['payload', 'isExpired'], false);
    router.window.location = `${process.env.REACT_APP_PHP_BASE_URL}${
      !isExpired ? '/logout.php' : '/logout.php?error=expired_session'
    }`;
  } catch (err) {
    yield handleActionError(err);
  }
}

export function* confirmLogOut(): Record<string, any> {
  try {
    const ok = yield DialogJSX.confirm({
      props: {
        title: 'Confirm log out',
        content: 'Are you sure you want to log out?',
        submitButtonLabel: 'Log out',
        cancelButtonLabel: 'Close'
      },
      inMobile: 'full'
    });
    if (ok) {
      yield put({ type: '@logout' });
    }
  } catch (err) {
    yield handleActionError(err);
  }
}

const userSaga = [
  takeEvery('@bootstrap', getUserInfo),
  takeEvery('@logout', logOut),
  takeEvery('@user/confirmLogout', confirmLogOut),
  takeEvery('@user/updateInfo', updateUserInfo)
];

export default userSaga;
