import { styled } from '@mui/material';

export const DialogHeader = styled('div', { name: 'DialogHeader' })(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '200px'
  })
);
