import Breadcrumbs from '@common/components/Breadcrumbs';
import Icon from '@common/components/Icon';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Header, HeaderAction } from './styled';
import router from '@utils/route/router';

export default function ClientDetailHeader() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const deleteClient = () => {
    dispatch({
      type: '@clients/deleteClient',
      payload: { id, isDetail: true }
    });
  };

  const createFlight = () => {
    router.window.location = `${process.env.REACT_APP_PHP_BASE_URL}/flights.php?new&client=${id}`;
  };

  return (
    <Header sx={{ px: 8, pt: 5, pb: 3 }}>
      <Breadcrumbs
        data={[
          { to: '/clients', label: 'Clients' },
          { label: 'Client detail' }
        ]}
      />
      <HeaderAction>
        <Button
          variant="outlined"
          color="error"
          size="large"
          startIcon={
            <Icon icon="icon-trash-fill" sx={{ color: 'accent.main' }} />
          }
          onClick={deleteClient}
        >
          Remove
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={createFlight}
          startIcon={<Icon icon="icon-plus" color="white" />}
        >
          Create flight
        </Button>
      </HeaderAction>
    </Header>
  );
}
