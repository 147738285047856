import useScrollRef from '@utils/scroll/useScrollRef';
import { debounce } from 'lodash';
import { useEffect } from 'react';

function getDocHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  );
}

const THRESHOLD = 500;

export default function useScrollEnd(onLoad?: () => void) {
  const scrollRef = useScrollRef();
  useEffect(() => {
    const node = scrollRef?.current ?? null;

    if (!onLoad) {
      return () => void 0;
    }

    const handle = node
      ? () => {
          if (
            THRESHOLD >
            node.scrollHeight - node.scrollTop - node.clientHeight
          ) {
            onLoad();
          }
        }
      : () => {
          if (
            window.scrollY + window.innerHeight + THRESHOLD > getDocHeight() &&
            window.scrollY > 0
          ) {
            onLoad();
          }
        };

    const bounce = debounce(handle, 20, { leading: true });

    if (node) {
      node.addEventListener('scroll', bounce);

      return () => node.removeEventListener('scroll', bounce);
    }

    window.addEventListener('scroll', bounce);

    return () => window.removeEventListener('scroll', bounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return onLoad;
}
