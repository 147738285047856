import { styled } from '@mui/material';
export const FlightEmbedListWrapper = styled('div', {
  name: 'FlightEmbedListWrapper'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingRight: theme.spacing(8)
}));
