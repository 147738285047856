import { styled } from '@mui/material';

export const Wrapper = styled('div', {
  name: 'Wrapper'
})(({ theme }) => ({
  height: '100%',
  flex: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  display: 'flex',
  paddingRight: theme.spacing(8),
  flexDirection: 'row-reverse'
}));
