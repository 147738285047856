import NavBar from '@common/components/Menu/NavBar';
import ErrorBoundary from '@services/ErrorBoundary';
import AuthorProvider from '@utils/authentication/AuthorProvider';
import { Outlet } from 'react-router-dom';
import { Main } from './styled';
import { Helmet } from 'react-helmet';

export default function MainLayout() {
  return (
    <ErrorBoundary>
      <Helmet>
        <meta name="viewport" content="width=1440" />
      </Helmet>
      <AuthorProvider>
        <NavBar />
        <Main>
          <Outlet />
        </Main>
      </AuthorProvider>
    </ErrorBoundary>
  );
}
