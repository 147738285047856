import Cookies from 'js-cookie';
import * as React from 'react';

export interface globalConfig {
  locale?: string;
  theme?: string;
  loaded?: boolean;
}
export type GlobalContextType = {
  config: globalConfig;
  setGlobalConfig: (config: globalConfig) => void;
};

const GlobalContext = React.createContext<GlobalContextType | null>(null);

const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [config, setConfig] = React.useState<globalConfig>({
    theme: Cookies.get('MTF-theme-A4RNTX2') ?? 'default',
    locale: Cookies.get('MTF-language--A4RNTX2') ?? 'en',
    loaded: true
  });
  const setGlobalConfig = (newConfig: globalConfig) => {
    setConfig({ ...config, ...newConfig });
    if (!!newConfig.locale) {
      Cookies.set('MTF-language--A4RNTX2', newConfig.locale);
    }
    if (!!newConfig.theme) {
      Cookies.set('MTF-theme-A4RNTX2', newConfig.theme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <GlobalContext.Provider value={{ config: config, setGlobalConfig }}>
      {children}
    </GlobalContext.Provider>
  );
};
export function useGlobal(): GlobalContextType | null {
  return React.useContext(GlobalContext);
}

export default GlobalProvider;
