import { useSelector } from 'react-redux';
import { Wrapper, LoadingCircle } from './styled';
import { loadingSelector } from '@core/selector';

export default function LoadingOverlay() {
  const loading = useSelector(loadingSelector);
  return (
    <Wrapper sx={{ display: loading ? 'flex' : 'none' }}>
      <LoadingCircle src="/images/loading_circle.png" alt="" />
    </Wrapper>
  );
}
