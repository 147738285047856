import { Box, IconButton, Typography } from '@mui/material';
import { IdealLegItemWrapper } from './styled';
import Icon from '@common/components/Icon';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

export default function IdealLegItem({ data, user_id }) {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const onDelete = () => {
    dispatch({
      type: '@client/deleteRoute',
      payload: { user_id: user_id, route_id: data.id },
      meta: { setDeleting }
    });
  };

  return (
    <IdealLegItemWrapper deleting={deleting}>
      <Box className="flex-center-rows " sx={{ flex: 1, gap: 3 }}>
        <Box className="flex-center-rows w-100">
          <Icon
            icon="icon-flight-takeoff-fill"
            sx={{ color: 'primary.main', mr: 1.5 }}
          />
          <div className="flex-column">
            <Typography
              color="neutral.dark3"
              variant="subtitle3"
              noWrap
              sx={{ pr: 2 }}
            >
              {data.departure_city}
            </Typography>
            <Typography
              color="neutral.dark1"
              variant="body2"
              noWrap
              sx={{ pr: 2 }}
            >
              {data.departure_country}
            </Typography>
          </div>
        </Box>
        <Box className="flex-center-rows w-100">
          <Icon
            icon="icon-flight-land-fill"
            sx={{ color: 'primary.main', mr: 1.5 }}
          />
          <div className="flex-column">
            <Typography
              color="neutral.dark3"
              variant="subtitle3"
              noWrap
              sx={{ pr: 2 }}
            >
              {data.arrival_city}
            </Typography>
            <Typography
              color="neutral.dark1"
              variant="body2"
              noWrap
              sx={{ pr: 2 }}
            >
              {data.arrival_country}
            </Typography>
          </div>
        </Box>
      </Box>
      <IconButton
        size="medium"
        onClick={onDelete}
        sx={{
          cursor: 'pointer',
          color: 'neutral.dark1',
          mr: -0.5,
          '&:hover': { color: 'accent.dark1' }
        }}
      >
        <Icon onClick={onDelete} icon="icon-trash-fill" />
      </IconButton>
    </IdealLegItemWrapper>
  );
}
