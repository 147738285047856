import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { BpCheckedIcon, BpIcon } from './CheckboxForm';

const ALLERGIES_LIST = [
  { id: 1, name: 'Celery' },
  { id: 2, name: 'Gluten' },
  { id: 3, name: 'Crustaceans' },
  { id: 4, name: 'Eggs' },
  { id: 5, name: 'Fish' },
  { id: 6, name: 'Lupin' },
  { id: 7, name: 'Milk' },
  { id: 8, name: 'Mollusca' },
  { id: 9, name: 'Mustard' },
  { id: 10, name: 'Tree nuts' },
  { id: 11, name: 'Sesame seeds' },
  { id: 12, name: 'Peanuts' },
  { id: 13, name: 'Soybeans' }
];

const AllergiesForm = ({ config, name }: any) => {
  const [field, , { setValue }] = useField(name);
  const { label } = config;
  const [valueId, setValueId] = useState(
    field?.value?.map(item => item.id) ?? []
  );
  useEffect(() => {
    setValue(valueId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueId]);

  const handleChange = (event: any) => {
    setValueId(event.target.value as string);
  };
  return (
    <FormControl fullWidth variant="filled" size="medium">
      <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
      <Select
        label="demo-simple-select-standard-label"
        value={valueId}
        multiple
        MenuProps={{
          PaperProps: {
            sx: { maxHeight: 264 }
          }
        }}
        onChange={handleChange}
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected?.map(item => (
              <Chip
                key={item - 1}
                label={ALLERGIES_LIST[item - 1]?.name}
                size="small"
                color="warning"
              />
            ))}
          </Box>
        )}
      >
        {ALLERGIES_LIST?.map((item, index) => {
          return (
            <MenuItem key={index} disableRipple value={item.id}>
              <Checkbox
                size="small"
                checked={valueId.indexOf(item.id) > -1}
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
              />
              <span>{item.name}</span>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default AllergiesForm;
