import Icon from '@common/components/Icon';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Button,
  DialogActions,
  IconButton,
  Typography
} from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';
import { useRef } from 'react';

export function CustomActionBar(props: any) {
  const {
    className,
    startDate,
    endDate,
    setValue,
    ownerState,
    setOpen,
    cancelRef
  } = props;

  const initStartDate = useRef(startDate);
  const initEndDate = useRef(endDate);

  const onSubmit = () => {
    if (startDate && endDate) {
      setValue({ startDate, endDate });
      ownerState.onChange(startDate);
      setOpen(false);
    } else {
      setValue({
        startDate: initStartDate.current,
        endDate: initEndDate.current
      });
      ownerState.onChange(initStartDate.current);
    }
    setOpen(false);
  };

  const onExit = () => {
    setValue({
      startDate: initStartDate.current,
      endDate: initEndDate.current
    });
    ownerState.onChange(initStartDate.current);
    setOpen(false);
  };

  return (
    <DialogActions className={className} sx={{ pb: 2, pt: 0, px: 4 }}>
      <Button
        variant="outlined"
        color="inherit"
        onClick={onExit}
        ref={cancelRef}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={onSubmit}
        disabled={!startDate || !endDate}
      >
        OK
      </Button>
    </DialogActions>
  );
}

export function CustomToolBar(props: any) {
  const { onViewChange, view, onMonthChange, currentMonth } = props;
  const selectNextMonth = () =>
    onMonthChange(currentMonth.add(1, 'month'), 'left');
  const selectPreviousMonth = () =>
    onMonthChange(currentMonth.subtract(1, 'month'), 'right');

  return (
    <Box sx={{ p: 4, pb: 1.5 }} className="flex-space-between-row">
      <IconButton size="small" onClick={selectPreviousMonth} sx={{ ml: -1 }}>
        <Icon icon="icon-Chevron-left-filled" />
      </IconButton>
      <Box
        className="flex-center-rows"
        sx={{ gap: 3, flex: 1, justifyContent: 'center' }}
      >
        <Box
          className="flex-center-rows"
          sx={{ cursor: 'pointer' }}
          onClick={() => onViewChange(view === 'month' ? 'day' : 'month')}
        >
          <Typography variant="subtitle2">
            {currentMonth.format('MMMM')}
          </Typography>
          <IconButton size="small" sx={{ p: 0 }}>
            <ArrowDropDownIcon />
          </IconButton>
        </Box>
        <Box
          className="flex-center-rows"
          sx={{ cursor: 'pointer' }}
          onClick={() => onViewChange(view === 'year' ? 'day' : 'year')}
        >
          <Typography variant="subtitle2">
            {currentMonth.format('YYYY')}
          </Typography>
          <IconButton size="small" sx={{ p: 0 }}>
            <ArrowDropDownIcon />
          </IconButton>
        </Box>
      </Box>
      <IconButton size="small" onClick={selectNextMonth} sx={{ mr: -1 }}>
        <Icon icon="icon-Chevron-right-filled" />
      </IconButton>
    </Box>
  );
}

export function CustomDay(props: any) {
  const { day, startDate, endDate, onPickDate, ...remainProps } = props;

  const isStart = startDate
    ? day.format('DD-MM-YYYY') === startDate.format('DD-MM-YYYY')
    : false;
  const isBetween =
    startDate && endDate
      ? day.isAfter(startDate) && day.isBefore(endDate)
      : null;
  const isEnd = endDate
    ? day.format('DD-MM-YYYY') === endDate.format('DD-MM-YYYY')
    : false;

  const onMouseEnter = (day: Dayjs) => {
    if (!isStart && !isEnd) {
      return;
    }
  };

  if ((isStart || isEnd) && startDate && endDate)
    return (
      <div
        style={{ position: 'relative' }}
        className={classNames({
          'Mui-block-defaultSelect': true,
          'Mui-started-background': isStart && !startDate.isSame(endDate),
          'Mui-ended-background': isEnd && !startDate.isSame(endDate)
        })}
      >
        <PickersDay
          {...remainProps}
          day={day}
          onDaySelect={(day: Dayjs) => onPickDate(day)}
          onMouseEnter={onMouseEnter}
          className={classNames({
            'Mui-selected': isStart || isEnd,
            'Mui-started': isStart,
            'Mui-ended': isEnd
          })}
        />
      </div>
    );
  return (
    <PickersDay
      {...remainProps}
      day={day}
      onDaySelect={(day: Dayjs) => onPickDate(day)}
      className={classNames({
        'Mui-block-defaultSelect': true,
        'Mui-selected': isStart || isEnd,
        'Mui-started': isStart,
        'Mui-ended': isEnd,
        'Mui-between': isBetween
      })}
    />
  );
}
