import { styled } from '@mui/material';

export const ClientDetailWrapper = styled('div', {
  name: 'ClientDetailWrapper'
})(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
}));

export const Body = styled('div', { name: 'ClientDetailBody' })(
  ({ theme }) => ({
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    flex: 1
  })
);

export const FlightCLientWrapper = styled('div', {
  name: 'FlightCLientWrapper'
})(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
}));

export const EmbedFlightContainer = styled('div', {
  name: 'EmbedFlightContainer'
})(({ theme }) => ({
  position: 'absolute',
  overflow: 'hidden',
  height: '100%',
  left: 64,
  right: 0,
  marginLeft: theme.spacing(3),
  paddingLeft: '348px',
  paddingBottom: theme.spacing(3)
}));
