import { Box, Typography } from '@mui/material';
import { Banner, Title, Wrapper } from './styled';

export default function ExpiredLinkPage(props: any) {
  return (
    <Wrapper>
      <Box
        sx={{
          maxWidth: { md: '600px', xs: '100%' },
          height: { xs: '100%', md: 'auto' }
        }}
        className="flex-center-columns"
      >
        <Banner sx={{ width: '100%' }} className="flex-center-columns">
          <img src="/images/logo_line.svg" alt="" style={{ height: 40 }} />
        </Banner>
        <Box
          className="flex-center-columns"
          sx={{
            pl: { xs: 3, sm: 5 },
            pr: { xs: 3, sm: 5 },
            pt: { xs: 8, sm: 5 },
            pb: { xs: 8, sm: 5 },
            backgroundColor: 'neutral.light3',
            flex: { xs: 1, md: 'auto' }
          }}
        >
          <img src="/images/expired.svg" alt="" />
          <Title sx={{ mt: 1, mb: 3 }}>Expired link</Title>
          <ul>
            <li>
              <Typography variant="body2" color="neutral.dark3">
                The link you clicked may have expired or you have already
                completed this registration process or something on our end may
                have gone wrong.
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="neutral.dark3">
                We want to get you up and running with <b>AvıoNexus</b> as soon
                as possible so please contact us at{' '}
                <a
                  className="highlight"
                  href="mailto:enquiries@maketechfly.com"
                >
                  enquiries@maketechfly.com
                </a>{' '}
                so that we can assist you.
              </Typography>
            </li>
          </ul>
        </Box>
      </Box>
    </Wrapper>
  );
}
