import { ClientDetail, ClientList } from '@client/index';
import { ListLayout, MainLayout, RegisterLayout } from '@common/layouts';
import { USER_LEVEL } from '@constant/user_level';
import { FlightList } from '@flight/index';
import { RegisterPage } from '@register/index';
import DefaultNavigate from '@utils/authentication/DefaultNavigate';
import { uniqueId } from 'lodash';
import { createBrowserRouter } from 'react-router-dom';

const permissionConvertor = (id: string, levels: number[]) => {
  return JSON.stringify({ [id]: levels.join('-') });
};

const router = createBrowserRouter([
  {
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <DefaultNavigate />
      },
      {
        element: <ListLayout />,
        children: [
          {
            path: '/clients',
            element: <ClientList />,
            id: permissionConvertor(uniqueId(), [
              USER_LEVEL.SUPERUSER,
              USER_LEVEL.BROKER
            ])
          },
          {
            path: '/flights',
            element: <FlightList />,
            id: permissionConvertor(uniqueId(), [
              USER_LEVEL.SUPERUSER,
              USER_LEVEL.CLIENT,
              USER_LEVEL.BROKER,
              USER_LEVEL.FBO,
              USER_LEVEL.OPERATOR
            ])
          }
          // {
          //   path: '/flights/fbo',
          //   element: <FlightListFBO />,
          //   id: permissionConvertor(uniqueId(), [USER_LEVEL.FBO])
          // }
        ]
      },
      {
        path: '/client/:id',
        element: <ClientDetail />,
        id: permissionConvertor(uniqueId(), [
          USER_LEVEL.SUPERUSER,
          USER_LEVEL.BROKER
        ])
      },
      // {
      //   path: '/flights/mayfly',
      //   element: <MayFlyFBO />,
      //   id: permissionConvertor(uniqueId(), [USER_LEVEL.FBO])
      // }
    ]
  },
  {
    element: <RegisterLayout />,
    children: [
      {
        path: '/register',
        element: <RegisterPage />
      }
    ]
  },
  {
    path: '*',
    element: <ClientList />
  }
]);

export default router;
