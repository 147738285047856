import { styled } from '@mui/material';

export const Header = styled('div', { name: 'ClientDetailHeader' })(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.neutral.light3
  })
);
export const HeaderAction = styled('div', { name: 'ClientDetailHeaderAction' })(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2)
  })
);
