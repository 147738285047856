import { styled } from '@mui/material';
export const FlightListWrapper = styled('div', { name: 'FlightListWrapper' })(
  ({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  })
);
