import { AddClientSchema } from '@client/helper/schema';
import Icon from '@common/components/Icon';
import TextFieldForm from '@common/formElement/TextFieldForm';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Footer from '../ActionFooter';
import { Instruction, Wrapper } from './styled';

export default function AddClientDialog(props: any) {
  const { closeDialog, cancelButtonLabel, submitButtonLabel, setForceOpen } =
    props;
  const [openInstruction, setOpenInstruction] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    setForceOpen(isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  const dispatch = useDispatch();
  const handleSubmit = (value: any, formik: FormikHelpers<any>) => {
    dispatch({
      type: '@client/addNewClient',
      payload: { data: value },
      meta: { setSubmitting, closeDialog }
    });
  };

  return (
    <Wrapper>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          backgroundColor: 'primary.light3',
          borderRadius: 2,
          maxWidth: '536px',
          mb: 1
        }}
      >
        <Box
          className="flex-center-rows"
          sx={{ cursor: 'pointer', width: 'fit-content' }}
          onClick={() => setOpenInstruction(prev => !prev)}
        >
          <Icon
            icon="icon-question-fill"
            color="inherit"
            sx={{ color: 'primary.main', mr: 1 }}
          />
          <Typography variant="body2" color="primary.dark1">
            How does this work?
          </Typography>
        </Box>
        <Typography variant="caption" color="primary.dark3">
          <Instruction open={openInstruction}>
            <span>
              If there is an AvıoNexus account associated with that email
              address:
            </span>
            <ul>
              <li>The user will be added to your client list.</li>
              <li>
                The user will be notified you have added them as a client.
              </li>
            </ul>
            <span>
              If there is <b>not</b> an AvıoNexus account associated with that
              email address:
            </span>
            <ul>
              <li>The user will be added to your client list as pending.</li>
              <li>
                The system will email the client with a signup link informing
                them you have invited them to the system as a client of yours.
              </li>
              <li>
                You will be notified when they sign up and they will no longer
                show as pending on your client list.
              </li>
              <li>
                If after 48 hours they have not signed up, their pending entry
                on your client list will be removed and their signup link will
                expire.
              </li>
            </ul>
          </Instruction>
        </Typography>
      </Paper>
      <Typography variant="body1" color="neutral.dark3">
        Please enter an email address below.
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Formik
          initialValues={{ email: '', firstName: '', lastName: '' }}
          onSubmit={handleSubmit}
          validationSchema={AddClientSchema}
          validateOnMount
        >
          {({ initialValues, isValid }) => (
            <Form>
              <TextFieldForm
                name="email"
                config={{
                  label: 'Email address',
                  fullWidth: true,
                  required: true
                }}
                initialValues={initialValues}
              />
              <Box sx={{ mt: 1 }}>
                <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
                  <Grid item xs={12} sm={6}>
                    <TextFieldForm
                      name="firstName"
                      config={{
                        label: 'First name (optional)',
                        fullWidth: true
                      }}
                      initialValues={initialValues}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldForm
                      name="lastName"
                      config={{
                        label: 'Last name (optional)',
                        fullWidth: true
                      }}
                      initialValues={initialValues}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Footer
                cancelButtonLabel={cancelButtonLabel}
                submitButtonLabel={submitButtonLabel}
                closeDialog={closeDialog}
                disabledSubmit={!isValid || isSubmitting}
                isSubmitting={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </Wrapper>
  );
}
