import { styled } from '@mui/material';

export const Wrapper = styled('div', { name: 'LoadingWrapper' })(
  ({ theme }) => ({
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    height: '100%',
    zIndex: 10,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.neutral.light1,
    opacity: 0.8
  })
);

export const LoadingCircle = styled('img', { name: 'LoadingCircle' })(
  ({ theme }) => ({
    marginBottom: '40px',
    animation: 'rotation 1s infinite linear',
    webkitAnimation: 'rotation 1s infinite linear'
  })
);
