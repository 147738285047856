import { PASSWORD_REGEX } from '@constant/regex';
import * as Yup from 'yup';

export const ConfirmDetailSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .required('Please input your password')
    .matches(
      PASSWORD_REGEX,
      'Password should be 8 to 15 characters with at least 1 uppercase, 1 lowercase, 1 numeric, and 1 special character.'
    ),
  confirmPassword: Yup.string().when('password', (password, field) =>
    password
      ? field
          .required('Please confirm your password')
          .oneOf(
            [Yup.ref('password')],
            'The password confirmation does not match'
          )
      : field
  )
});
