import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';

const MayFlyButton = () => {
  const navigate = useNavigate();
  const goToMayfly = () => {
    navigate('/flights/mayfly');
  };
  return (
    <Button
      variant="contained"
      color="secondary"
      size="large"
      onClick={goToMayfly}
    >
      {`See MayFly list for ${dayjs().add(1, 'day').format('Do of MMM YYYY')}`}
    </Button>
  );
};

export const actionButtonList = {
  mayflyButton: <MayFlyButton />
};
