import dayjs from 'dayjs';
import * as Yup from 'yup';

export const AddClientSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required')
});

export const EditClientSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phone_country_code: Yup.string().required('Country code is required'),
  mobile: Yup.string()
    .required('Phone number is required')
    .matches(/^[0-9]+$/, 'The phone number is invalid')
    .test(
      'maxLength',
      'Phone number must be between 8 and 15 characters',
      val => val.toString().length < 16 && val.toString().length > 7
    ),
  sex: Yup.string().required('Gender is required'),
  pax_weight: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, 'The weight is invalid')
    .max(3, 'Exceeds max length. Please reduce input size'),
  passport_issue_date: Yup.string().nullable(),
  passport_expiry_date: Yup.string()
    .nullable()
    .test({
      name: 'max',
      exclusive: false,
      message: 'To date must be greater than or equal to From date',
      test: function (value) {
        return !dayjs(value, 'DD/MM/YYYY').isBefore(
          dayjs(this.parent.passport_issue_date, 'DD/MM/YYYY')
        );
      }
    })
});

export const AddRouteSchema = Yup.object().shape({
  departure_id: Yup.string().required('Please select a city'),
  arrival_id: Yup.string().required('Please select a city')
});
