import ErrorFallback from '@common/components/ErrorFallback';
import React, { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

interface State {
  error: Error;
  errorInfo: React.ErrorInfo;
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null, hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error,
      errorInfo
    });
  }

  render() {
    if (this.state.hasError) {
      const { error, errorInfo } = this.state;

      return <ErrorFallback error={error} errorInfo={errorInfo} />;
    }
    const { children } = this.props;
    return children;
  }
}
