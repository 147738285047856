import { TableCell, styled } from '@mui/material';
export const ClientItemWrapper = styled('div', {
  name: 'ClientItemWrapper',
  shouldForwardProp: prop => prop !== 'deleting'
})<{ deleting?: boolean }>(({ theme, deleting }) => ({
  borderRadius: '4px',
  cursor: 'pointer',
  display: 'flex',
  height: '64px',
  backgroundColor: theme.palette.neutral.white,
  boxShadow: '0px 2px 4px 0px rgb(88,92,97, 0.24)',
  overflow: 'hidden',
  ...(deleting === true && {
    opacity: 0.3,
    pointerEvents: 'none',
    cursor: 'default'
  })
}));

export const AvatarWrapper = styled('div', { name: 'AvatarWrapper' })(
  ({ theme }) => ({
    width: '64px',
    backgroundColor: theme.palette.neutral.light3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  })
);
export const MenuWrapper = styled('div', { name: 'MenuWrapper' })(
  ({ theme }) => ({
    width: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    paddingRight: theme.spacing(2)
  })
);

export const TableWrapper = styled('div', {
  name: 'TableItemWrapper'
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  height: '100%',
  marginRight: '-48px',
  paddingRight: '48px',
  '.MuiGrid-item': {
    display: 'flex',
    alignItems: 'center'
  },
  '&:hover:not(:has(.icon:hover))': {
    backgroundColor: theme.palette.primary.light3,
    transition: 'all 0.2s ease-in-out'
  }
}));

export const StyledTableCell = styled(TableCell, {
  name: 'StyledTableCell'
})(({ theme }) => ({
  paddingTop: 8,
  paddingBottom: 8,
  maxWidth: 300,
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));
