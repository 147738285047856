import { USER_LEVEL } from '@constant/user_level';
import { userLevelSelector } from '@user/selector/userInfoSelector';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

export default function DefaultNavigate() {
  const userLevel = useSelector(userLevelSelector);
  const to = useMemo(() => {
    switch (userLevel) {
      case USER_LEVEL.BROKER:
      case USER_LEVEL.SUPERUSER:
        return '/clients';
      case USER_LEVEL.OPERATOR:
      case USER_LEVEL.CLIENT:
        return '/flights';
      case USER_LEVEL.FBO:
        return '/flights/fbo';
    }
  }, [userLevel]);

  return <Navigate to={to} replace />;
}
