import { Box, styled } from '@mui/material';

export const Wrapper = styled('div', { name: 'AddClientDialogWrapper' })(
  ({ theme }) => ({
    overflow: 'hidden',
    padding: '24px 32px'
  })
);

export const Title = styled('div', {
  name: 'RegisterTitle',
  shouldForwardProp: prop => prop !== 'step'
})<{ step?: number }>(({ theme, step }) => ({
  fontFamily: 'Montserrat',
  fontSize: '48px',
  lineHeight: '60px',
  letterSpacing: '0px',
  fontWeight: 700,
  color: theme.palette.primary.dark3,
  position: 'relative',
  ...(step !== 0 && {
    '::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url("/images/logo_line.svg")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center right',
      backgroundSize: 'min(calc(50%), 248px)',
      height: '100%'
    }
  }),
  [theme.breakpoints.down('lg')]: {
    fontSize: '32px',
    lineHeight: '40px',
    backgroundSize: 'min(calc(50%), 200px)'
    // ...(step !== 0 && {

    // })
  }
}));

export const SubTitle = styled('div', { name: 'RegisterSubTitle' })(
  ({ theme }) => ({
    fontFamily: 'Montserrat',
    fontSize: '18px',
    lineHeight: '22.5px',
    letterSpacing: '0px',
    fontWeight: 600,
    color: theme.palette.primary.dark2,
    '.note': {
      color: theme.palette.neutral.main
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '20px'
    }
  })
);

export const Content = styled(Box, { name: 'StepContent' })(({ theme }) => ({
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    marginBottom: 0,
    paddingBottom: '149px',
    height: 'fit-content'
  }
}));

export const FooterAction = styled('div', { name: 'FooterAction' })(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      bottom: 0,
      boxShadow: '0px -2px 8px 0px rgb(88,92,97, 0.24)',
      left: 0,
      zIndex: 5,
      margin: 0,
      right: 0,
      padding: ' 24px',
      backgroundColor: theme.palette.neutral.light3,
      borderTop: `1px solid ${theme.palette.neutral.light1}`,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      '.icon': {
        display: 'none'
      }
    }
  })
);

export const OnfidoWrapper = styled(Box, { name: 'OnfidoWrapper' })(
  ({ theme }) => ({
    height: '510px',
    '.onfido': {
      maxWidth: '500px !important',
      transform: 'scale(0.85)',
      transformOrigin: 'top left'
    },
    [theme.breakpoints.down('sm')]: {
      height: '500px',
      '.onfido': {
        transform: 'scale(1)',
        transformOrigin: 'top left',
        minHeight: '500px !important'
      }
    }
  })
);
