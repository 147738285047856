import { guessInstance } from '@instance/userInstance';
import { takeEvery } from 'redux-saga/effects';
import allergiesSaga from './allergiesStep';
import confirmDetailSaga from './confirmDetailStep';
import secureAccountSaga from './secureStep';
import identityStepSaga from './verifyIdentityStep';

export function* verifyToken(action: any): Record<string, any> {
  const { token, setLoading, setExpiredLink, setUserDetail } = action.payload;

  try {
    if (!token || !token.length) {
      throw new Error('No token found');
    }
    //init form data

    //verify token
    const response = yield guessInstance.request({
      url: '/onboarding/get-initial-details',
      method: 'GET',
      params: { token, timestamp: new Date().getTime() }
    });
    //call success
    setLoading(false);

    //return UI
    if (response?.data?.status === 'success') {
      setUserDetail(response?.data?.data);
      try {
        //@ts-ignore
        FS('setIdentity', {
          uid: response?.data?.data?.id
        });
      } catch (err) {}
    } else {
      setExpiredLink(true);
    }
  } catch (err) {
    setExpiredLink(true);
    setLoading(false);
  }
}

const registerSaga = [
  takeEvery('@register/verifyToken', verifyToken),
  ...confirmDetailSaga,
  ...identityStepSaga,
  ...allergiesSaga,
  ...secureAccountSaga
];

export default registerSaga;
