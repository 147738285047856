import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { produce } from 'immer';
import { get, unset } from 'lodash';

export const flightsReducer = createSlice({
  name: 'flight',
  initialState: {
    flight: {},
    paging: {}
  },
  reducers: {
    INIT: (state, action) => {
      const { paging } = action.payload;
      state.paging[paging] = {
        ...state.paging[paging],
        loading: true
      };
    },
    FULLFIL: (state, action) => {
      const { data: flightList, meta, paging } = action.payload;
      const { next_cursor } = meta;
      const fullfilData: Record<any, string> = {};
      // GET CURRENT PAGING DATA
      const pagingData: number[] = get(state, ['paging', paging, 'data'], []);

      flightList.forEach((item: any) => {
        // MAP ARRAY DATA TO OBJECT ENTITY
        fullfilData[item.id] = item;

        // PUSH NEW ITEM TO PAGING DATA
        pagingData.push(parseInt(item.id));
      });
      //UPDATE NEW OBJECT ENTITY
      state.flight = {
        ...get(state, 'flight', {}),
        ...fullfilData
      };

      //UPDATE PAGING DATA
      state.paging[paging] = {
        data: pagingData,
        next_cursor: next_cursor ? next_cursor : 'end',
        meta: {
          total: meta.total,
          last_page: meta.last_page
        },
        refreshing: false
      };
    },
    fetchDetail: (state, action) => {
      const { data: flightData } = action.payload;
      const newState = {
        ...get(state, 'flight', {}),
        [flightData.id]: flightData
      };
      state.flight = newState;
    },
    deleteEntity: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      const newState = produce(state.flight, draft => {
        unset(draft, id);
      });
      state.flight = newState;
    },
    updateEntity: (state, action: PayloadAction<Record<string, any>>) => {
      const { id, data } = action.payload;
      const newState = produce(state.flight, draft => {
        draft[id] = { ...draft[id], ...data };
      });
      state.flight = newState;
    },
    refreshPaging: (state, action: PayloadAction<Record<string, any>>) => {
      const pagingData = state.paging;
      Object.keys(pagingData).forEach(key => {
        pagingData[key] = {
          refreshing: true,
          data: [],
          next_cursor: null
        };
      });
    }
  }
});
