import { userLevelSelector } from '@user/selector/userInfoSelector';
import router from '@utils/route/router';
import { select, takeEvery } from 'redux-saga/effects';

export function* createFlight(action: any) {
  const userLevel = yield select(userLevelSelector);

  const prefix = [1, 5].includes(userLevel) ? 'flights' : 'index';

  router.window.location = `${process.env.REACT_APP_PHP_BASE_URL}/${prefix}.php?new`;
}

const sagaEffect = [takeEvery('@flight/creteFlight', createFlight)];

export default sagaEffect;
