//@ts-nocheck
export class DialogJSX {
  _currentGlobalLoader = null;
  static registerDialog(ref) {
    this._currentGlobalLoader = ref;
  }

  static open({ ...args }) {
    if (this._currentGlobalLoader) {
      this._currentGlobalLoader.open({ ...args });
    }
  }

  static updateProps({ ...props }, index) {
    if (this._currentGlobalLoader) {
      this._currentGlobalLoader.updateProps({ ...props }, index);
    }
  }

  static close(index) {
    if (this._currentGlobalLoader) {
      this._currentGlobalLoader.close(index);
    }
  }

  static async confirm(props) {
    if (this._currentGlobalLoader) {
      return this._currentGlobalLoader.confirm(props);
    }
  }
}
