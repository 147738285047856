import AutoCompleteField from '@common/formElement/AutocompleteForm';
import { Box, Grid } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { Wrapper } from './styled';
import { AddRouteSchema } from '@client/helper/schema';
import Footer from '../ActionFooter';

export default function AddRouteDialog(props: any) {
  const { closeDialog, cancelButtonLabel, submitButtonLabel, id } = props;
  const dispatch = useDispatch();
  const handleSubmit = (value: any, formik: FormikHelpers<any>) => {
    dispatch({ type: '@client/addNewRoute', payload: { data: value, id: id } });
    closeDialog();
  };

  return (
    <Wrapper>
      <Formik
        initialValues={{}}
        onSubmit={handleSubmit}
        validationSchema={AddRouteSchema}
        validateOnMount
      >
        {({ initialValues, isValid }) => (
          <Form>
            <Box sx={{ mt: 2, pb: 2 }}>
              <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
                <Grid item xs={12} sm={6}>
                  <AutoCompleteField
                    name="departure_id"
                    config={{
                      label: 'Departure city',
                      fullWidth: true,
                      required: true,
                      search_endpoint: '/cities',
                      uniqueKey: 'citiList',
                      noOptionsText: 'No cities found'
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutoCompleteField
                    name="arrival_id"
                    config={{
                      label: 'Arrival city',
                      fullWidth: true,
                      required: true,
                      search_endpoint: '/cities',
                      uniqueKey: 'citiList',
                      noOptionsText: 'No cities found'
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Footer
              cancelButtonLabel={cancelButtonLabel}
              submitButtonLabel={submitButtonLabel}
              closeDialog={closeDialog}
              disabledSubmit={!isValid}
            />
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
}
