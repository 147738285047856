import { Box, Button, Typography } from '@mui/material';
import IdealLegItem from './IdealLegItem';
import Icon from '@common/components/Icon';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import AddRouteDialog from '@common/dialog/AddRouteDialog';
import { useParams } from 'react-router';
import { useRef } from 'react';

export default function AccountIdealLeg({ loading, emptyLegs = [] }) {
  const { id } = useParams();
  const scrollRef = useRef();

  const addRoute = () => {
    DialogJSX.open({
      component: AddRouteDialog,
      props: {
        title: 'Add a new route',
        submitButtonLabel: 'Add route',
        cancelButtonLabel: 'Cancel',
        id: id
      },
      inMobile: 'full'
    });
  };

  return (
    <Box
      sx={{ mt: 3, pr: 8, overflow: 'auto', height: '100%' }}
      ref={scrollRef}
    >
      {emptyLegs.length > 0 ? (
        emptyLegs.map((item, index) => {
          return <IdealLegItem key={index} data={item} user_id={id} />;
        })
      ) : (
        <Typography
          variant="body2"
          color="neutral.dark1"
          component="div"
          sx={{ pb: 1 }}
        >
          <i>
            This client has not let us know of any empty legs they would be
            interested in. <br /> If you know of any routes they want to travel
            please add them below
          </i>
        </Typography>
      )}
      <Box sx={{ mt: 3, mb: 2, pb: 4 }} className="flex-center-rows">
        <Button
          variant="contained"
          color="secondary"
          size="large"
          sx={{ mr: 3 }}
          disabled={emptyLegs.length === 10}
          onClick={addRoute}
          startIcon={<Icon icon="icon-plus" color="white" />}
        >
          Add a route
        </Button>
        <Typography variant="body2" color="neutral.dark1">{`${
          10 - emptyLegs.length
        }/10 remaining legs`}</Typography>
      </Box>
    </Box>
  );
}
