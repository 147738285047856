import { userInfoSelector } from '@user/selector/userInfoSelector';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

export default function useAirportCode() {
  const userInfo = useSelector(userInfoSelector);

  const airportCodeConfig = get(userInfo, 'airport_code_preference', 'local');
  return airportCodeConfig;
}
