import { styled } from '@mui/material';

export const Wrapper = styled('div', { name: 'BootstrappingWrapper' })(
  ({ theme }) => ({
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    backgroundColor: theme.palette.neutral.white,
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '.logo': {
      height: '50px',
      [theme.breakpoints.down('md')]: {
        height: '25px'
      }
    }
  })
);

export const LoadingCircle = styled('img', { name: 'LoadingCircle' })(
  ({ theme }) => ({
    marginBottom: '40px',
    animation: 'rotation 1s infinite linear',
    webkitAnimation: 'rotation 1s infinite linear'
  })
);

export const DotWrapper = styled('div', { name: 'DotWrapper' })(
  ({ theme }) => ({
    display: 'flex',
    gap: '20px',
    marginTop: '40px',
    '&>:nth-of-type(0)': {
      animationDelay: '0s'
    },
    '&>:nth-of-type(1)': {
      animationDelay: '0.2s'
    },
    '&>:nth-of-type(2)': {
      animationDelay: '0.4s'
    },
    '&>:nth-of-type(3)': {
      animationDelay: '0.6s'
    }
  })
);

export const Dot = styled('div', { name: 'Dot' })(({ theme }) => ({
  '@keyframes scaling': {
    '20%': {
      backgroundColor: theme.palette.neutral.light3,
      transform: 'translateY(-20px) scale(0.6)'
    },
    '40%, 60%': {
      transform: 'translateY(0px) scale(1)',
      backgroundColor: theme.palette.primary.main
    },
    '80%': {
      transform: 'translateY(-20px) scale(0.6)',
      backgroundColor: theme.palette.neutral.light3
    }
  },
  width: '18px',
  height: '18px',
  borderRadius: '100%',
  animation: 'scaling 2s ease-in-out infinite',
  [theme.breakpoints.down('md')]: {
    width: '8px',
    height: '8px'
  }
}));
