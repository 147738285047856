import { Grid, Typography } from '@mui/material';
import { TableHeaderWrapper, TableWrapper } from './styled';
const FlightEmbedTableHeader = () => {
  return (
    <TableHeaderWrapper>
      <TableWrapper>
        <Grid container>
          <Grid sx={{ pl: 2 }} item xs={2}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Flight No.
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Departing from
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Legs
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Flight type
            </Typography>
          </Grid>
        </Grid>
      </TableWrapper>
    </TableHeaderWrapper>
  );
};

export default FlightEmbedTableHeader;
