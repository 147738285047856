import { get } from 'lodash';
import { createSelector } from 'reselect';

const getListEntity = (state: any, paging: string, module: string) => {
  return get(state, [module, 'paging', paging], { data: [] });
};
export const listEntitySelector = createSelector(getListEntity, data => data);

const getEntityById = (state: any, module: string, id: number) => {
  return get(state, `${module}.${module}.${id}`, {});
};
export const entitySelector = createSelector(getEntityById, data => data);
