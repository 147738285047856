import { uniqueId } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';

export default function useSuggestions<T = Record<string, any>>({
  apiUrl,
  limit,
  initialParams,
  searchable,
  uniqueKey,
  callApiOnMount
}: {
  apiUrl: string;
  isCached?: boolean;
  limit?: number;
  initialParams?: Record<string, any>;
  searchable?: boolean;
  uniqueKey?: string;
  callApiOnMount?: boolean;
}): [
  {
    items: T[];
    loading: boolean;
    error?: undefined;
  },
  (query: string) => void
] {
  const mounted = React.useRef(true);
  const [none] = React.useState<string>(uniqueKey ?? uniqueId('s'));

  const [data, setData] = React.useState<{
    items: T[];
    loading: boolean;
    error?: undefined;
  }>({
    items: [],
    loading: true
  });

  const dispatch = useDispatch();

  React.useEffect(() => {
    callApiOnMount && handleChange('');
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSuggestions = (data: any) => {
    if (mounted.current && Array.isArray(data.items)) setData(data);
  };

  const handleChange = (q: string) => {
    dispatch({
      type:
        q.length > 0 && searchable ? '@suggestion/search' : '@suggestion/all',
      payload: {
        q,
        none,
        apiUrl,
        limit,
        initialParams,
        searchable
      },
      meta: { onSuccess: handleSuggestions }
    });
  };

  return [data, handleChange];
}
