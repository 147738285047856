import { useRegister } from '@context/RegisterContext';
import { Box, Button, Typography } from '@mui/material';
import { Content, FooterAction, Title } from './_styled';
import Icon from '@common/components/Icon';
import { useSearchParams } from 'react-router-dom';

export default function WelcomeOnboard() {
  const { nextStep } = useRegister();
  const [searchParams] = useSearchParams();
  const orgName = searchParams.get('orgName') || '';

  return (
    <Box>
      <Content>
        <Title step={0}>Welcome!</Title>
        <Box sx={{ mt: { xs: 1, sm: 2 } }}>
          <Typography variant="body2" color="neutral.black">
            You have been invited by <b>{orgName}</b> to join AvıoNexus!
          </Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="primary.dark3">
            Complete your registration in just 10 minutes. Our secure onboarding
            process only requires a valid passport and a quick selfie.
          </Typography>
        </Box>

        <Box
          sx={{
            mt: { xs: 2, sm: 6 },
            backgroundColor: 'neutral.light3',
            p: 1.5
          }}
        >
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Icon icon="icon-passport-bold" size="md" />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25 }}>
              <Typography variant="subtitle3" color="neutral.dark3">
                A valid passport
              </Typography>
              <Typography variant="caption" color="neutral.dark2">
                As part of our onboarding process we scan your passport to
                capture the data.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ mt: 2, backgroundColor: 'neutral.light3', p: 1.5 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Icon icon="icon-scan-smiley-fill" size="md" />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25 }}>
              <Typography variant="subtitle3" color="neutral.dark3">
                A selfie
              </Typography>
              <Typography variant="caption" color="neutral.dark2">
                Required during the passport scanning phase. This will be for
                your ID, used to verify your identity and gain entry at the
                airport.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ mt: 2, backgroundColor: 'neutral.light3', p: 1.5 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Icon icon="icon-timer-fill" size="md" />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25 }}>
              <Typography variant="subtitle3" color="neutral.dark3">
                10 minutes of your time
              </Typography>
              <Typography variant="caption" color="neutral.dark2">
                Our onboarding process takes just 10 minutes and only has to be
                completed once.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Content>
      <FooterAction sx={{ mt: { xs: 0, sm: 8 } }}>
        <Button
          sx={{ width: { xs: '100%', sm: 'auto' } }}
          onClick={nextStep}
          size="large"
          color="primary"
          variant="contained"
        >
          Get Started
        </Button>
      </FooterAction>
    </Box>
  );
}
