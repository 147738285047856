import { styled } from '@mui/material';

export const Main = styled('div', { name: 'ListLayoutMain' })(({ theme }) => ({
  paddingLeft: '64px',
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
  flexDirection: 'column',
  [theme.breakpoints.down('lg')]: {
    padding: '0px 24px'
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '210px'
  }
}));
