import { guessInstance } from '@instance/userInstance';
import handleActionError from '@services/handleActionError';
import { put, takeEvery } from 'redux-saga/effects';

export function* submitFirstStep(action: any): Record<string, any> {
  const { value, token, setUserDetail, nextStep } = action.payload;

  try {
    if (!token || !token.length) {
      throw new Error('No token found');
    }

    //submit detail
    yield put({ type: '@loading/show' });
    const response = yield guessInstance.request({
      url: '/onboarding/update-page',
      method: 'PUT',
      params: {
        ...value,
        token,
        page: 1
      }
    });

    yield put({ type: '@loading/hide' });
    if (response.data.status === 'success') {
      setUserDetail((prev: any) => {
        return {
          ...prev,
          ...value
        };
      });
      nextStep();
    }
  } catch (err) {
    yield handleActionError(err);
  }
}

const confirmDetailSaga = [
  takeEvery('@register/submitConfirmDetail', submitFirstStep)
];

export default confirmDetailSaga;
