import DirectLink from '@common/components/DirectLink';
import { entitySelector } from '@core/selector/entitySelector';
import { getName, tooltipFlightStatusData } from '@helper/stringFunction';
import { Box, Typography } from '@mui/material';
import { userLevelSelector } from '@user/selector/userInfoSelector';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import Leg from './Leg';
import { FlightItemWrapper } from './styled';
import { useMemo } from 'react';
import { USER_LEVEL } from '@constant/user_level';

export default function FlightTableItem({ id }: { id: number }) {
  const data = useSelector(state => entitySelector(state, 'flight', id));
  const userLevel = useSelector(userLevelSelector);
  const isFBOView = userLevel === USER_LEVEL.FBO;

  //WORK AROUND WHEN NOT IMPLEMENT FLIGHT DETAIL PAGE
  const prefix = useMemo(() => {
    switch (userLevel) {
      case 1:
      case 5:
        return 'flights';
      case 2:
      case 3:
      case 7:
        return 'index';
    }
  }, [userLevel]);

  if (isEmpty(data)) return null;

  const {
    flight_code,
    assignee,
    flight_status,
    flight_lines,
    customer: poc
  } = data;
  const statusData = tooltipFlightStatusData(flight_status);

  return (
    <DirectLink
      key={id}
      href={`${process.env.REACT_APP_PHP_BASE_URL}/${prefix}.php?flight=${id}`}
    >
      <FlightItemWrapper className="flex-column">
        <Box
          className="flex-space-between-row header"
          sx={{
            width: '100%',
            py: 1.5,
            px: 2,
            backgroundColor: 'primary.light3',
            alignItems: 'center'
          }}
        >
          <Box className="flex-column">
            <Typography variant="head4Mons" color="neutral.dark3">
              {flight_code}
            </Typography>
            <Typography
              variant="body2"
              color="neutral.dark3"
              component="div"
              sx={{ mt: 0.5 }}
            >
              {isFBOView ? (
                <>
                  Organised by{' '}
                  <b>{flight_lines[0]?.operator?.organisation_name ?? 'N/A'}</b>
                </>
              ) : (
                <>
                  Assigned to <b>{assignee ? getName(assignee) : 'N/A'}</b>
                </>
              )}
            </Typography>
          </Box>
          {isFBOView ? null : (
            <Box className="flex-column" sx={{ alignItems: 'flex-end' }}>
              <Box
                sx={{
                  px: 2,
                  py: 0.75,
                  mb: 0.5,
                  backgroundColor: statusData.color,
                  borderRadius: 4,
                  border: `1px solid`,
                  borderColor: statusData.border,
                  color: statusData.textColor,
                  lineHeight: '14.63px'
                }}
              >
                <Typography variant="head5Mons">{statusData.text}</Typography>
              </Box>
              <Typography variant="caption" color="neutral.dark3">
                Point of contact <b>{poc ? getName(poc) : 'N/A'}</b>
              </Typography>
            </Box>
          )}
        </Box>

        {/* FLIGHT CONTENT */}
        {flight_lines.map((item, index) => (
          <Leg key={index} item={item} />
        ))}
      </FlightItemWrapper>
    </DirectLink>
  );
}
