import {
  AccountIdealLeg,
  AccountInfoBlock,
  ClientDetailHeader
} from '@client/components/client-detail';
import Icon from '@common/components/Icon';
import FlightEmbedBlock from '@flight/components/flight-embed/FlightEmbedBlock';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import useFetchDetail from 'src/hooks/useFetchDetail';
import {
  Body,
  ClientDetailWrapper,
  EmbedFlightContainer,
  FlightCLientWrapper
} from './styled';

const ClientDetail = () => {
  const { id } = useParams();
  const [tabValue, setTabValue] = useState(1);
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onResendEmail = () => {
    dispatch({
      type: '@clients/onResendEmail',
      payload: { id: id }
    });
  };

  const [clientData, loading] = useFetchDetail(
    'client',
    '/clients/:id',
    parseInt(id)
  );

  useEffect(() => {
    return () => {
      dispatch({
        type: 'flight/refreshPaging'
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { activated } = clientData;

  return (
    <ClientDetailWrapper>
      <ClientDetailHeader />
      <Body sx={{ pl: 8 }}>
        <AccountInfoBlock loading={loading} clientData={clientData ?? {}} />
        {activated || loading ? (
          <EmbedFlightContainer>
            <Tabs
              sx={{
                pr: 8,
                backgroundColor: 'neutral.light3'
              }}
              onChange={handleChange}
              value={tabValue}
            >
              <Tab disableRipple label="Upcoming flights" value={1} />
              <Tab disableRipple label="Past flights" value={0} />
              <Tab disableRipple label="Ideal empty legs" value={2} />
            </Tabs>
            <FlightCLientWrapper>
              {tabValue !== 2 ? (
                <FlightEmbedBlock user_id={id} is_upcoming={tabValue} />
              ) : (
                <AccountIdealLeg
                  loading={loading}
                  emptyLegs={clientData?.emptyLegs}
                />
              )}
            </FlightCLientWrapper>
          </EmbedFlightContainer>
        ) : (
          <Box className="flex-center-columns" sx={{ flex: 1, mt: 18 }}>
            <img src="/images/awaiting_acceptable.svg" alt="" />
            <Typography
              variant="body2"
              color="neutral.dark1"
              component="div"
              sx={{ mt: 2 }}
            >
              <i>
                When this client has accepted your invitation their information
                will be displayed here.
              </i>
            </Typography>
            <Button
              variant="outlined"
              color="info"
              size="large"
              sx={{ mt: 2 }}
              onClick={onResendEmail}
              startIcon={
                <Icon icon="icon-Mail-fill" sx={{ color: 'primary.main' }} />
              }
            >
              Resend Activation Email
            </Button>
          </Box>
        )}
      </Body>
    </ClientDetailWrapper>
  );
};

export default ClientDetail;
