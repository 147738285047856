import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

type StyleDict = Record<string, React.CSSProperties | string>;

function globalCss(global: StyleDict, theme: Theme) {
  //BUTTON
  global['.MuiButton-startIcon'] = {
    marginLeft: '0px !important'
  };
  global['.MuiButtonBase-root.MuiButton-root'] = {
    whiteSpace: 'nowrap',
    minWidth: 'auto',
    borderRadius: '4px',
    textTransform: 'none',
    fontWeight: 600
  };
  global[
    '.MuiButtonBase-root.MuiButton-root.MuiButton-contained.Mui-disabled'
  ] = {
    backgroundColor: theme.palette.neutral.main,
    color: theme.palette.neutral.dark2
  };
  global[
    '.MuiButtonBase-root.MuiButton-root.MuiButton-contained.Mui-disabled .MuiButton-icon .icon'
  ] = {
    color: theme.palette.neutral.dark2
  };
  global['.MuiButtonBase-root.MuiButton-sizeLarge'] = {
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    height: '42px',
    padding: '8px 22px'
  };
  global['.MuiButtonBase-root.MuiButton-sizeMedium'] = {
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    height: '36px',
    padding: '6px 16px'
  };
  global['.MuiButtonBase-root.MuiButton-sizeSmall'] = {
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.46px',
    height: '30px',
    padding: '4px 10px'
  };
  global['.MuiButtonBase-root.MuiButton-contained.MuiButton-colorPrimary'] = {
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.accent.light3
  };
  global[
    '.MuiButtonBase-root.MuiButton-contained.MuiButton-colorPrimary:hover'
  ] = {
    backgroundColor: theme.palette.accent.dark1
  };
  global['.MuiButtonBase-root.MuiButton-contained.MuiButton-colorSecondary'] = {
    backgroundColor: theme.palette.primary.dark2,
    color: theme.palette.neutral.white
  };
  global[
    '.MuiButtonBase-root.MuiButton-contained.MuiButton-colorSecondary:hover'
  ] = {
    backgroundColor: theme.palette.primary.dark3
  };
  global['.MuiButtonBase-root.MuiButton-contained.MuiButton-colorError'] = {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.neutral.white
  };
  global['.MuiButtonBase-root.MuiButton-contained.MuiButton-colorError:hover'] =
    {
      backgroundColor: theme.palette.danger.dark1
    };
  global['.MuiButtonBase-root.MuiButton-contained.MuiButton-colorWarning'] = {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.neutral.white
  };
  global[
    '.MuiButtonBase-root.MuiButton-contained.MuiButton-colorWarning:hover'
  ] = {
    backgroundColor: theme.palette.warning.dark1
  };
  global['.MuiButtonBase-root.MuiButton-contained.MuiButton-colorInfo'] = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral.white
  };
  global['.MuiButtonBase-root.MuiButton-contained.MuiButton-colorInfo:hover'] =
    {
      backgroundColor: theme.palette.primary.dark1
    };
  global['.MuiButtonBase-root.MuiButton-contained.MuiButton-colorSuccess'] = {
    backgroundColor: theme.palette.positive.dark1,
    color: theme.palette.neutral.white
  };
  global[
    '.MuiButtonBase-root.MuiButton-contained.MuiButton-colorSuccess:hover'
  ] = {
    backgroundColor: theme.palette.positive.dark2
  };

  global['.MuiButtonBase-root.MuiButton-outlined.MuiButton-colorInherit'] = {
    backgroundColor: theme.palette.neutral.white,
    color: theme.palette.primary.dark3,
    border: `1px solid ${theme.palette.neutral.main}`
  };
  global['.MuiButtonBase-root.MuiButton-outlined.Mui-disabled'] = {
    backgroundColor: theme.palette.neutral.light2,
    color: theme.palette.neutral.dark1,
    border: `1px solid ${theme.palette.neutral.main}`
  };
  global[
    '.MuiButtonBase-root.MuiButton-outlined.MuiButton-colorInherit:hover'
  ] = {
    backgroundColor: theme.palette.neutral.white
  };

  //TABS
  global['.MuiTabs-scroller.MuiTabs-fixed'] = {
    height: '100%',
    display: 'flex',
    boxShadow: `inset 0 -1px 0 ${theme.palette.neutral.light2}`
  };

  global['.MuiButtonBase-root.MuiTab-root'] = {
    textTransform: 'none',
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.15px',
    fontWeight: 700,
    color: theme.palette.neutral.dark1
  };
  global['.MuiButtonBase-root.MuiTab-root:first-child'] = {
    paddingLeft: 0
  };

  global['.MuiButtonBase-root.MuiTab-root.Mui-selected'] = {
    color: theme.palette.primary.dark1
  };
  global['.MuiTabs-root .MuiTabs-indicator'] = {
    height: 1
  };

  //INPUT
  global['.MuiInputBase-root.MuiFilledInput-root'] = {
    backgroundColor: theme.palette.neutral.light3
  };
  global['.MuiInputBase-root.MuiFilledInput-root.MuiInputBase-sizeSmall'] = {
    height: '48px',
  };
  global['.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-sizeSmall'] = {
    height: '48px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 1.43,
    letterSpacing: '0.17px'
  };

  global['.MuiInputBase-root.MuiFilledInput-root.searchBar'] = {
    backgroundColor: `${theme.palette.neutral.white} !important`
  };
  global[
    '.MuiInputBase-root.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error)'
  ] = {
    backgroundColor: theme.palette.neutral.light2
  };
  global['.MuiInputBase-root.MuiFilledInput-root.Mui-disabled'] = {
    backgroundColor: theme.palette.neutral.dark1,
    color: theme.palette.neutral.dark1
  };
  global['.MuiInputBase-root.MuiFilledInput-root.Mui-disabled::before'] = {
    borderBottom: `2px solid ${theme.palette.neutral.dark2}`
  };

  global['.MuiInputBase-root.MuiFilledInput-root.Mui-focused'] = {
    backgroundColor: theme.palette.primary.light3
  };
  global['.MuiInputBase-root.MuiFilledInput-root::before'] = {
    borderBottom: `2px solid ${theme.palette.neutral.light1}`,
    transformOrigin: 'left'
  };

  global['.MuiInputBase-root.MuiFilledInput-root::after'] = {
    transformOrigin: 'left',
    borderBottom: `2px solid ${theme.palette.primary.main} !important`
  };
  global['.MuiInputBase-root.MuiFilledInput-root.Mui-error::before'] = {
    transformOrigin: 'left',
    borderBottom: `2px solid ${theme.palette.danger.main}`
  };
  global[
    '.MuiInputBase-root.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before'
  ] = {
    borderBottom: `2px solid ${theme.palette.neutral.dark1}`
  };
  global['.MuiFormLabel-root.MuiInputLabel-root.Mui-error'] = {
    color: theme.palette.danger.main
  };
  global['.MuiInputLabel-root.Mui-focused'] = {
    color: `${theme.palette.primary.main} !important`
  };
  global['.MuiInputLabel-root:not(.Mui-focused, .MuiInputLabel-shrink)'] = {
    color: theme.palette.neutral.dark1
  };
  global['.MuiFormLabel-asterisk'] = {
    color: theme.palette.danger.main
  };
  global['.MuiInputBase-input.MuiFilledInput-input.Mui-disabled'] = {
    backgroundColor: theme.palette.neutral.main,
    color: theme.palette.neutral.dark2,
    WebkitTextFillColor: theme.palette.neutral.dark2
  };

  global['.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled'] = {
    color: theme.palette.neutral.dark2
  };

  global[
    '.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled .MuiFormLabel-asterisk'
  ] = {
    color: theme.palette.neutral.dark2
  };

  global['.MuiInputBase-root.MuiFilledInput-root.Mui-disabled::before'] = {
    borderBottom: `2px solid ${theme.palette.neutral.dark1}`
  };

  //PAGINATION
  global['.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected'] = {
    backgroundColor: theme.palette.primary.light2,
    color: theme.palette.primary.main
  };

  //ICON
  global['.icon-black'] = {
    color: theme.palette.neutral.black
  };
  global['.icon-white'] = {
    color: theme.palette.neutral.white
  };
  global['.icon-primary'] = {
    color: theme.palette.primary.dark1
  };
  global['.icon-primary.haveBackground'] = {
    backgroundColor: theme.palette.primary.light2
  };
  global['.icon-primary.haveBackground:hover'] = {
    boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.dark1}`
  };
  global['.icon-accent'] = {
    color: theme.palette.accent.dark1
  };
  global['.icon-accent.haveBackground'] = {
    backgroundColor: theme.palette.accent.light3
  };
  global['.icon-accent.haveBackground:hover'] = {
    boxShadow: `inset 0px 0px 0px 1px ${theme.palette.accent.dark1}`
  };
  global['.icon-danger'] = {
    color: theme.palette.danger.dark1
  };
  global['.icon-danger.haveBackground'] = {
    backgroundColor: theme.palette.danger.light2
  };
  global['.MuiIcon-root.MuiIcon-fontSizeMedium'] = {
    width: '24px',
    height: '24px'
  };
  global['.icon-warning'] = {
    color: theme.palette.warning.dark1
  };
  global['.icon-warning.haveBackground'] = {
    backgroundColor: theme.palette.warning.light2
  };
  global['.icon-warning.haveBackground:hover'] = {
    boxShadow: `inset 0px 0px 0px 1px ${theme.palette.warning.dark1}`
  };
  global['.icon-positive'] = {
    color: theme.palette.positive.dark1
  };
  global['.icon-positive.haveBackground'] = {
    backgroundColor: theme.palette.positive.light3
  };
  global['.icon-positive.haveBackground:hover'] = {
    boxShadow: `inset 0px 0px 0px 1px ${theme.palette.positive.dark1}`
  };
  global['.icon-lightAccent'] = {
    color: theme.palette.accent.light3
  };

  //SELECT
  global['.MuiList-root.MuiMenu-list'] = {
    padding: 0,
    fontSize: '14px !important'
  };
  global[
    '.MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root.MuiInputBase-small'
  ] = {
    height: '48px',
    padding: '14px 40px 14px 16px'
  };
  global[
    '.MuiInputBase-input.MuiSelect-select.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall'
  ] = {
    padding: '14px 40px 14px 16px'
  };
  global['.MuiButtonBase-root.MuiMenuItem-root'] = {
    height: '52px',
    fontSize: '14px',
    padding: '16px 16px',
    backgroundColor: theme.palette.neutral.white
  };
  global['.MuiButtonBase-root.MuiMenuItem-root.Mui-selected'] = {
    backgroundColor: theme.palette.neutral.white,
    color: theme.palette.primary.main
  };
  global[
    '.MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]'
  ] = {
    backgroundColor: `${theme.palette.neutral.white} !important`,
    color: theme.palette.primary.main
  };
  global['.MuiButtonBase-root.MuiMenuItem-root.Mui-selected .icon'] = {
    color: theme.palette.primary.main
  };
  global['.MuiButtonBase-root.MuiMenuItem-root:not(:last-child)::after'] = {
    content: '""',
    position: 'absolute',
    left: 16,
    right: 16,
    bottom: 0,
    borderBottom: `1px solid ${theme.palette.neutral.light2} !important`
  };
  //MENUITEM
  global['.MuiMenuItem-root.Mui-focusVisible'] = {
    backgroundColor: `${theme.palette.neutral.white} !important`
  };
  global['.MuiMenuItem-root:hover'] = {
    backgroundColor: `${theme.palette.primary.light3} !important`
  };

  // SKELETON
  global['.MuiSkeleton-root'] = {
    animationDuration: '1.5s !important'
  };
  global['.MuiSkeleton-rectangular.avatar'] = {
    position: 'relative'
  };
  global['.MuiSkeleton-rectangular.avatar::after'] = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: '10px',
    backgroundImage: `url("/images/avionexus_icon_gray.svg")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center'
  };

  //TOOLTIP
  global['.MuiTooltip-tooltip'] = {
    backgroundColor: `${theme.palette.primary.dark3} !important`
  };
  global['.MuiTooltip-arrow'] = {
    color: `${theme.palette.primary.dark3} !important`
  };

  //DIVIDER
  global['.MuiDivider-root'] = {
    borderColor: `${theme.palette.neutral.light1} !important`
  };

  //DIALOG
  global['.MuiPaper-root.MuiDialog-paper'] = {
    borderRadius: '16px',
    maxWidth: '800px'
  };
  global['.MuiPaper-root.MuiDialog-paper.MuiDialog-paperFullScreen'] = {
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      maxHeight: '100%',
      height: 'auto'
    }
  };

  global['.MuiDialog-container'] = {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-end !important'
    }
  };
  global['.MuiDialogTitle-root'] = {
    padding: '16px 32px !important'
  };
  global['.MuiDialogContent-root'] = {
    padding: '0px !important',
    borderTop: `1px solid ${theme.palette.neutral.light1}`
  };
  //SNACKBAR
  global['.notistack-MuiContent-success'] = {
    fontFamily: 'Open Sans',
    fontWeight: 600
  };
  global['.notistack-MuiContent-error'] = {
    fontFamily: 'Open Sans',
    fontWeight: 600
  };
  //SLIDER
  global['.MuiSlider-root .MuiSlider-rail'] = {
    backgroundColor: theme.palette.neutral.light2,
    height: theme.spacing(1.5),
    borderRadius: '6px',
    opacity: 1
  };
  global['.MuiSlider-root .MuiSlider-track'] = {
    backgroundColor: theme.palette.primary.dark2,
    height: theme.spacing(1.5),
    borderRadius: '6px',
    opacity: 1,
    border: 'none'
  };
  global['.MuiSlider-root .MuiSlider-thumb'] = {
    backgroundColor: theme.palette.primary.dark2
  };
  global['.MuiSlider-root .MuiSlider-mark'] = {
    display: 'none'
  };
  global['.MuiSlider-root .MuiSlider-markLabel'] = {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    color: theme.palette.neutral.dark1
  };
  global['.MuiSlider-root .MuiSlider-markLabel.MuiSlider-markLabelActive'] = {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    color: theme.palette.primary.dark2
  };
  global[
    '.MuiSlider-root .MuiSlider-markLabel:nth-last-child(1 of .MuiSlider-markLabel)'
  ] = {
    transform: 'translateX(-100%)'
  };
  global[
    '.MuiSlider-root .MuiSlider-markLabel:nth-child(1 of .MuiSlider-markLabel)'
  ] = {
    transform: 'none'
  };
  //LINEAR
  global['.MuiLinearProgress-root.MuiLinearProgress-determinate'] = {
    backgroundColor: theme.palette.neutral.light2
  };
  global['.MuiCircularProgress-colorInherit'] = {
    color: theme.palette.neutral.light2
  };
  //AVATAR
  global['.MuiAvatar-root.MuiAvatar-circular'] = {
    fontWeight: '700',
    fontFamily: 'Montserrat'
  };
  //CHIP
  global['.MuiChip-root.MuiChip-sizeMedium'] = {
    fontSize: '14px',
    lineHeight: '20.02px',
    letterSpacing: '0.17px',
    fontWeight: '400',
    height: '28px'
  };
  global['.MuiChip-root.MuiChip-filledWarning'] = {
    backgroundColor: theme.palette.warning.light3,
    color: theme.palette.danger.dark1
  };
  //TITLE
  global['.MuiTypography-root.MontserratH4'] = {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22.5px',
    color: theme.palette.neutral.dark3
  };

  //TOGGLE
  global[
    '.MuiButtonBase-root.MuiToggleButtonGroup-groupedHorizontal.MuiToggleButton-root.MuiToggleButton-sizeSmall'
  ] = {
    height: '32px',
    minWidth: '80px',
    textAlign: 'center'
  };
  global[
    '.MuiButtonBase-root.MuiToggleButtonGroup-groupedHorizontal.MuiToggleButton-root.MuiToggleButton-sizeMedium'
  ] = {
    height: '40px'
  };
  global[
    '.MuiButtonBase-root.MuiToggleButtonGroup-groupedHorizontal.MuiToggleButton-root'
  ] = {
    borderColor: theme.palette.neutral.main,
    color: theme.palette.neutral.dark3,
    textTransform: 'none'
  };
  global[
    '.MuiButtonBase-root.MuiToggleButtonGroup-groupedHorizontal.MuiToggleButton-root.Mui-selected'
  ] = {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.primary.dark1}`,
    color: theme.palette.primary.dark2
  };
  global[
    '.MuiButtonBase-root.MuiToggleButtonGroup-groupedHorizontal.MuiToggleButton-root.MuiToggleButtonGroup-lastButton'
  ] = {
    marginLeft: 0
  };

  //DATE PICKER
  global['.MuiPickersLayout-root .MuiDateCalendar-root'] = {
    height: 'auto',
    maxHeight: 'unset',
    color: theme.palette.neutral.dark3,
    width: '336px'
  };

  global['.MuiDateCalendar-root .MuiDayCalendar-weekDayLabel'] = {
    margin: 0,
    marginBottom: '4px',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '31.92px',
    letterSpacing: '1px',
    padding: theme.spacing(0.5),
    width: '40px',
    color: theme.palette.neutral.dark3
  };
  global[
    '.MuiDateCalendar-root .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition'
  ] = {
    overflowX: 'unset',
    minHeight: '288px'
  };

  global['.MuiDateCalendar-root .MuiButtonBase-root.MuiPickersDay-root'] = {
    margin: 0,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20.02px',
    letterSpacing: '0.17px',
    padding: theme.spacing(0.5),
    width: '40px',
    height: '40px',
    color: theme.palette.neutral.dark3
  };

  global[
    '.MuiDateCalendar-root .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayOutsideMonth'
  ] = {
    color: `${theme.palette.neutral.light1} !important`
  };

  global['.MuiDateCalendar-root .MuiButtonBase-root.MuiPickersDay-root:hover'] =
    {
      backgroundColor: theme.palette.primary.light2
    };
  global['.MuiDateCalendar-root .MuiDayCalendar-weekContainer'] = {
    margin: 0,
    marginBottom: theme.spacing(1)
  };

  global[
    '.MuiDateCalendar-root .MuiButtonBase-root.MuiPickersDay-root.Mui-selected'
  ] = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral.white
  };

  global[
    '.MuiDateCalendar-root .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.Mui-block-defaultSelect:not(.Mui-started):not(.Mui-ended):not(.Mui-between)'
  ] = {
    backgroundColor: theme.palette.neutral.white,
    color: '#000'
  };

  global[
    '.MuiDateCalendar-root .MuiButtonBase-root.MuiPickersDay-root.Mui-between'
  ] = {
    backgroundColor: theme.palette.primary.light2,
    borderRadius: 0,
    color: theme.palette.neutral.dark3
  };

  global['.MuiDateCalendar-root .Mui-started-background::before'] = {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    left: '50%',
    bottom: 0,
    backgroundColor: theme.palette.primary.light2
  };
  global['.MuiDateCalendar-root .Mui-ended-background::before'] = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: '50%',
    bottom: 0,
    backgroundColor: theme.palette.primary.light2
  };
}

const useGlobalStyles = makeStyles((theme: Theme) => {
  const global: StyleDict = {
    '.srOnly': { display: 'none' },
    '.overflowHidden': { overflow: 'hidden' }
  };

  [globalCss].forEach(x => x(global, theme));

  return createStyles({ '@global': global });
});

export default useGlobalStyles;
