import RegisterProvider from '@context/RegisterContext';
import RegisterNavigate from '@register/common/RegisterNavigate';
import ErrorBoundary from '@services/ErrorBoundary';
import { Outlet } from 'react-router-dom';

export default function RegisterLayout() {
  return (
    <ErrorBoundary>
      <RegisterProvider>
        <RegisterNavigate />
        <Outlet />
      </RegisterProvider>
    </ErrorBoundary>
  );
}
