import DirectLink from '@common/components/DirectLink';
import Icon from '@common/components/Icon';
import { entitySelector } from '@core/selector/entitySelector';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { FlightEmbedItemWrapper, TableWrapper } from './styled';

function FlightEmbedItem({
  id,
  is_upcoming
}: {
  id: number;
  is_upcoming: number;
}) {
  const data = useSelector(state => entitySelector(state, 'flight', id));

  if (!data) return null;

  const { trip_type_name, flight_lines } = data;
  const placeholder = (
    <Typography variant="body2" color="neutral.main">
      <i>waiting</i>
    </Typography>
  );

  return (
    <FlightEmbedItemWrapper>
      <DirectLink
        href={`${process.env.REACT_APP_PHP_BASE_URL}/flights.php?flight=${id}`}
      >
        <TableWrapper>
          <Grid container>
            <Grid sx={{ pl: 2 }} item xs={2}>
              <Typography color="neutral.dark1" variant="body2" noWrap>
                {data.id}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Icon
                icon="icon-flight-takeoff-fill"
                sx={{
                  color: is_upcoming ? 'primary.main' : 'neutral.dark1',
                  mr: 1.5
                }}
              />
              <Box className="flex-column">
                <Typography
                  color="neutral.dark3"
                  variant="subtitle3"
                  noWrap
                  sx={{ pr: 2 }}
                >
                  {flight_lines[0].depart_airport.name}
                </Typography>
                <Typography
                  color="neutral.dark2"
                  variant="body2"
                  noWrap
                  sx={{ pr: 2 }}
                >
                  {flight_lines[0].depart_date}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Typography color="neutral.dark3" variant="body2" noWrap>
                {flight_lines.length ?? placeholder}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography color="neutral.dark3" variant="body2" noWrap>
                {trip_type_name ?? placeholder}
              </Typography>
            </Grid>
          </Grid>
        </TableWrapper>
      </DirectLink>
    </FlightEmbedItemWrapper>
  );
}

export default FlightEmbedItem;
