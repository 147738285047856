import LoadingOverlay from '@common/components/LoadingOverlay';
import { DialogProvider } from '@utils/dialog/dialogProvider';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import router from '@utils/route/router';
import ScrollProvider from '@utils/scroll/ScrollProvider';
import { useEffect, useRef } from 'react';
import { RouterProvider } from 'react-router-dom';
let globalDialogRef: any;

function App() {
  useEffect(() => {
    DialogJSX.registerDialog(globalDialogRef);
  }, []);

  const scrollRef = useRef(undefined);

  return (
    <ScrollProvider scrollRef={scrollRef}>
      <LoadingOverlay />
      <RouterProvider router={router} />
      <DialogProvider ref={ref => (globalDialogRef = ref)} />
    </ScrollProvider>
  );
}

export default App;
