import { Dot, DotWrapper, Wrapper } from './styled';

export default function BootstrappingPage(props: any) {
  return (
    <Wrapper>
      <img src="/images/logo_line.svg" alt="" className="logo" />
      <DotWrapper>
        {[1, 2, 3].map((item, index) => (
          <Dot key={index} />
        ))}
      </DotWrapper>
    </Wrapper>
  );
}
