import LoadingPage from '@common/components/BootstrappingPage';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ExpiredLinkPage from '@register/common/ExpiredLink';

type RegisterContextType = {
  step: number;
  nextStep: () => void;
  backStep: () => void;
  userDetail: UserDetailType;
  token: string;
  setUserDetail: React.Dispatch<React.SetStateAction<UserDetailType>>;
  initOnfido: boolean;
  setInitOnfido: React.Dispatch<React.SetStateAction<boolean>>;
};

type UserDetailType = Record<string, any>;

const RegisterContext = React.createContext<RegisterContextType>({
  step: 0,
  nextStep: () => null,
  backStep: () => null,
  userDetail: {
    firstName: '',
    lastName: '',
    email: ''
  },
  setUserDetail: () => null,
  token: '',
  initOnfido: false,
  setInitOnfido: () => null
});

export enum ONBOARDING_STEP {
  WELCOME = 0,
  CONFIRM_DETAIL = 1,
  VERIFY_IDENTITY = 2,
  ALLERGIES = 3,
  SECURE = 4,
  DONE = 5
}

const RegisterProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [step, setStep] = React.useState<number>(ONBOARDING_STEP.WELCOME);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [initOnfido, setInitOnfido] = React.useState<boolean>(false);
  const [expired, setExpiredLink] = React.useState<boolean>(false);
  const [userDetail, setUserDetail] = React.useState<UserDetailType>({
    firstName: '',
    lastName: '',
    email: ''
  });

  const nextStep = () => {
    setStep(prev => prev + 1);
  };
  const backStep = () => {
    setStep(prev => (prev - 1 < 0 ? 0 : prev - 1));
  };

  const token = searchParams.get('token') || '';

  React.useEffect(() => {
    dispatch({
      type: '@register/verifyToken',
      payload: {
        token,
        setLoading,
        setExpiredLink,
        setUserDetail
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RegisterContext.Provider
      value={{
        step,
        nextStep,
        backStep,
        userDetail,
        token,
        setUserDetail,
        initOnfido,
        setInitOnfido
      }}
    >
      {loading ? <LoadingPage /> : expired ? <ExpiredLinkPage /> : children}
    </RegisterContext.Provider>
  );
};
export function useRegister(): RegisterContextType {
  return React.useContext(RegisterContext);
}

export default RegisterProvider;
