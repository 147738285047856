import { guessInstance } from '@instance/userInstance';
import handleActionError from '@services/handleActionError';
import { put, takeEvery } from 'redux-saga/effects';

export function* submitThirdStep(action: any): Record<string, any> {
  const { value, token, nextStep, setUserDetail } = action.payload;

  try {
    if (!token || !token.length) {
      throw new Error('No token found');
    }

    //submit detail
    yield put({ type: '@loading/show' });
    const response = yield guessInstance.request({
      url: '/onboarding/update-page',
      method: 'PUT',
      data: {
        allergies: value,
        token,
        page: 4
      }
    });

    //call success
    yield put({ type: '@loading/hide' });
    if (response.data.status === 'success') {
      setUserDetail((prev: Record<string, any>) => {
        return { ...prev, allergies: value };
      });
      nextStep();
    }
  } catch (err) {
    yield handleActionError(err);
  }
}

const allergiesSaga = [takeEvery('@register/submitAllergies', submitThirdStep)];

export default allergiesSaga;
