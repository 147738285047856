import { ClientTable, ClientTableHeader } from '@client/components/client-list';
import { Box } from '@mui/material';
import useScrollRef from '@utils/scroll/useScrollRef';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useEntityFullfil from 'src/hooks/useEntityFullfil';

const ClientList = () => {
  const [searchParams] = useSearchParams();
  const params = useMemo(() => {
    return Object.fromEntries(searchParams.entries());
  }, [searchParams]);

  const [data, loading] = useEntityFullfil('client', '/clients', params, true);

  const ref = useScrollRef();

  useEffect(() => {
    ref.current.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, data.refreshing]);

  return (
    <>
      <ClientTableHeader />
      <Box sx={{ overflow: 'auto', pr: 8, ml: -1, pl: 1 }} ref={ref}>
        <ClientTable loading={loading} data={data} />
      </Box>
    </>
  );
};

export default ClientList;
