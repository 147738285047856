import { styled } from '@mui/material';

export const Main = styled('div', { name: 'Main' })(({ theme }) => ({
  paddingLeft: '96px',
  top: 0,
  position: 'fixed',
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
  right: 0,
  left: 0,
  bottom: 0,
  backgroundColor: theme.palette.neutral.light3,
  [theme.breakpoints.down('md')]: {
    paddingLeft: '0px',
    paddingTop: '80px'
  }
}));
