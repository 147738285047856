import { Button, Divider, Typography } from '@mui/material';
import { Wrapper } from './styled';

export default function ConfirmDialog(props: any) {
  const {
    setValue,
    closeDialog,
    content,
    cancelButtonLabel,
    submitButtonLabel
  } = props;
  const onYes = () => {
    setValue(true);
    closeDialog();
  };
  const onNo = () => {
    setValue(false);
    closeDialog();
  };
  return (
    <>
      <Wrapper>
        <Typography variant="body1" color="neutral.dark3">
          {content}
        </Typography>
      </Wrapper>
      <Divider />
      <Wrapper
        sx={{ justifyContent: 'space-between' }}
        className="flex-center-rows"
      >
        <Button onClick={onNo} variant="outlined" color="inherit">
          {cancelButtonLabel}
        </Button>
        <Button onClick={onYes} variant="contained" color="error">
          {submitButtonLabel}
        </Button>
      </Wrapper>
    </>
  );
}
