import FakeLoadingProgressBar from '@common/components/FakeLoadingProgressBar';
import Icon from '@common/components/Icon';
import { useRegister } from '@context/RegisterContext';
import { Box, Button, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Content, FooterAction, OnfidoWrapper } from './_styled';

export default function VerifyIdentity({ scrollRef }) {
  //hook
  const {
    backStep,
    nextStep,
    userDetail,
    token,
    initOnfido,
    setInitOnfido,
    step
  } = useRegister();
  const dispatch = useDispatch();
  //state
  const [loading, setLoading] = useState<boolean>(true);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [showFaceNote, setShowFaceNote] = useState<boolean>(false);
  const [done, loadDone] = useState<boolean>(false);
  const [showInstruction, setShowInstruction] = useState<boolean>(true);
  //ref
  const onfidoRef = useRef<any>();
  const focusRef = useRef<any>();
  //unmount onfido when leave step
  const source = axios.CancelToken.source();
  const cancelToken = source.token;

  useEffect(() => {
    window.addEventListener('userAnalyticsEvent', (event: any) => {
      if (event.detail.eventName === 'WELCOME') {
        loadDone(true);
        setTimeout(() => {
          setInitOnfido(true);
          setShowInstruction(false);
        }, 1000);
        setTimeout(() => {
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }, 1300);
      }
      if (event.detail.eventName === 'FACIAL_INTRO') {
        setShowFaceNote(true);
      }
    });
    return () => {
      window.removeEventListener('userAnalyticsEvent', () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initOnfido || step !== 2) {
      return;
    }
    dispatch({
      type: '@register/verifyIdentity',
      payload: {
        userDetail,
        nextStep,
        setDisabledButton,
        cancelToken,
        setLoading,
        onfidoRef,
        token
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    const Onfido = onfidoRef.current;
    return () => {
      source.cancel();
      Onfido && Onfido.tearDown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box style={{ height: '100%' }} className="flex-space-between-column">
      <Content>
        <Typography
          sx={{ mt: { xs: 1, md: 4 } }}
          component="div"
          variant="body2"
          color="neutral.dark3"
        >
          {showFaceNote
            ? 'Let’s make sure nobody’s impersonating'
            : 'We need to verify your identity, please ensure the bottom lines on the passport are visible in your photo'}
        </Typography>
        {showInstruction || loading ? (
          <Box sx={{ width: '100%', mt: 3 }}>
            {step === 2 ? <FakeLoadingProgressBar done={done} /> : null}
            <Grid container columnSpacing={1} rowSpacing={0} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6}>
                <img
                  src="/images/Passport_Good.svg"
                  alt=""
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <img
                  src="/images/Passport_Bad.svg"
                  alt=""
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </Box>
        ) : null}
        <OnfidoWrapper
          ref={focusRef}
          sx={{
            mt: 3,
            display: showInstruction || loading ? 'none' : 'block',
            minHeight: '400px'
          }}
        >
          <div id="onfido-mount"></div>
        </OnfidoWrapper>
      </Content>
      <FooterAction>
        <Button
          onClick={backStep}
          size="large"
          variant="outlined"
          color="inherit"
          startIcon={<Icon icon="icon-previous-arrow" />}
        >
          Previous step
        </Button>
        <Button
          onClick={nextStep}
          size="large"
          variant="contained"
          disabled={disabledButton}
          color="primary"
          endIcon={
            <Icon
              icon="icon-previous-arrow"
              className="icon-flip-x"
              color="lightAccent"
            />
          }
        >
          Next step
        </Button>
      </FooterAction>
    </Box>
  );
}
