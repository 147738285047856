import { Box, Typography } from '@mui/material';

export default function EmptyClientTable() {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '380px',
        backgroundColor: 'neutral.white',
        justifyContent: 'center',
        borderRadius: '8px'
      }}
      className="flex-center-columns"
    >
      <Box>
        <img src="/images/empty_client_table.svg" alt="" />
      </Box>
      <Typography
        sx={{ mt: 3 }}
        component="div"
        variant="body1"
        color="neutral.dark3"
      >
        No clients found.
      </Typography>
    </Box>
  );
}
