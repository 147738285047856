import RegisterSuccessDialog from '@common/dialog/RegisterSuccessDialog';
import { guessInstance } from '@instance/userInstance';
import handleActionError from '@services/handleActionError';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import { put, takeEvery } from 'redux-saga/effects';

export function* submitFinalStep(action: any): Record<string, any> {
  const { value, token, setUserDetail, nextStep } = action.payload;

  try {
    if (!token || !token.length) {
      throw new Error('No token found');
    }

    //submit detail
    yield put({ type: '@loading/show' });
    const response = yield guessInstance.request({
      url: '/onboarding/update-page',
      method: 'PUT',
      params: {
        ...value,
        token,
        page: 5
      }
    });

    //call success
    yield put({ type: '@loading/hide' });
    if (response.data.status === 'success') {
      setUserDetail((prev: Record<string, any>) => {
        return { ...prev, ...value };
      });
      nextStep();
      DialogJSX.open({
        component: RegisterSuccessDialog,
        props: { closeable: false }
      });
    }
  } catch (err) {
    yield handleActionError(err);
  }
}

const secureAccountSaga = [
  takeEvery('@register/secureAccount', submitFinalStep)
];

export default secureAccountSaga;
