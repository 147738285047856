import { createSlice } from '@reduxjs/toolkit';
import { merge } from 'lodash';

export const userReducer = createSlice({
  name: 'user',
  initialState: {
    userInfo: null
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    updateInfo: (state, action) => {
      state.userInfo = merge(state.userInfo, action.payload);
    }
  }
});
