import { Link, SxProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

type LinkProps = {
  to?: string;
  href?: string;
  children: React.ReactNode;
  sx?: SxProps;
  className?: string;
  onClick?: (props?: any) => void;
};

export default function DirectLink({
  to,
  href,
  children,
  sx,
  className,
  onClick,
  ...props
}: LinkProps) {
  if (to)
    return (
      <RouterLink
        onClick={onClick}
        style={{
          height: '100%',
          width: '100%',
          textDecoration: 'none'
        }}
        to={to}
        {...props}
      >
        {children}
      </RouterLink>
    );
  return (
    <Link
      sx={{
        textDecoration: 'none',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        ...sx
      }}
      {...props}
      className={className}
      href={to ?? href}
    >
      {children}
    </Link>
  );
}
