import Slider from '@mui/material/Slider';
import { useField } from 'formik';
const marks = [
  {
    value: 0,
    label: 'This flight'
  },
  {
    value: 50,
    label: 'Until my passport expires'
  },
  {
    value: 100,
    label: 'Forever'
  }
];
const marksMapping = [
  {
    value: 0,
    valueSubmit: 0
  },
  {
    value: 50,
    valueSubmit: 1
  },
  {
    value: 100,
    valueSubmit: 2
  }
];
const SliderField = ({ config, name, initialValues }: any) => {
  const [field, , { setValue }] = useField(name);

  function getLabelFromValue(value: number) {
    return marks.filter(item => item.value === value)[0].label;
  }

  function getValueSubmit(value: number) {
    return marksMapping.filter(item => item.value === value)[0].valueSubmit;
  }

  function getValueFromDefaultValue(value: number) {
    return marksMapping.filter(item => item.valueSubmit === value)[0].value;
  }

  return (
    <Slider
      id="slider"
      defaultValue={getValueFromDefaultValue(initialValues[name])}
      aria-labelledby="discrete-slider"
      valueLabelDisplay="auto"
      getAriaValueText={getLabelFromValue}
      marks={marks}
      disableSwap={true}
      step={null}
      min={0}
      max={100}
      onChange={(e, value) => {
        const numberValue = Array.isArray(value) ? value[0] : value;
        setValue(getValueSubmit(numberValue));
      }}
      onBlur={field.onBlur}
    />
  );
};

export default SliderField;
