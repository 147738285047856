import { Box } from '@mui/material';
import useScrollRef from '@utils/scroll/useScrollRef';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useEntityFullfil from 'src/hooks/useEntityFullfil';
import EmptyFlightTable from './EmptyFlight';
import FlightEmbedTableHeader from './Header';
import FlightEmbedItem from './Item';
import FlightEmbedItemSkeleton from './Item/LoadingSkeleton';
import { FlightEmbedListWrapper } from './styled';

const FlightEmbedBlock = ({
  user_id,
  is_upcoming
}: {
  user_id: string;
  is_upcoming: number;
}) => {
  const [searchParams] = useSearchParams();
  const params = useMemo(() => {
    return {
      ...Object.fromEntries(searchParams.entries()),
      user_id,
      is_upcoming,
      view: 'client_flights'
    };
  }, [is_upcoming, searchParams, user_id]);

  const [data, loading] = useEntityFullfil('flight', '/flights', params, true);

  const ref = useScrollRef();

  return (
    <>
      {!(loading || !data?.next_cursor) && data?.data.length === 0 ? null : (
        <FlightEmbedTableHeader />
      )}
      <Box ref={ref} sx={{ overflow: 'auto', height: '100%', pb: 8 }}>
        <FlightEmbedListWrapper>
          {loading && !data?.next_cursor ? (
            Array(10)
              .fill(0)
              .map((_, index) => <FlightEmbedItemSkeleton key={index} />)
          ) : data?.data.length ? (
            data?.data.map((item: number, index: number) => (
              <FlightEmbedItem
                key={index}
                id={item}
                is_upcoming={is_upcoming}
              />
            ))
          ) : (
            <EmptyFlightTable />
          )}
          {data.next_cursor && loading
            ? Array(5)
                .fill(0)
                .map((_, index) => <FlightEmbedItemSkeleton key={index} />)
            : null}
        </FlightEmbedListWrapper>
      </Box>
    </>
  );
};

export default FlightEmbedBlock;
