import { styled } from '@mui/material';

export const AvatarWrapper = styled('div', { name: 'AvatarWrapper' })(
  ({ theme }) => ({
    width: '64px '
  })
);
export const MenuWrapper = styled('div', { name: 'MenuWrapper' })(
  ({ theme }) => ({
    width: '48px '
  })
);

export const TableHeaderWrapper = styled('div', { name: 'TableHeaderWrapper' })(
  ({ theme }) => ({
    display: 'flex',
    overflow: 'hidden',
    zIndex: 1,
    right: 0,
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(8),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.neutral.light3,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  })
);
export const TableWrapper = styled('div', {
  name: 'TableHeadTableWrapper'
})(({ theme }) => ({
  flex: 1
}));
