import Icon from '@common/components/Icon';
import { TextField } from '@mui/material';
import { useField } from 'formik';
import React, { useState } from 'react';

const PasswordField = ({ config, name, formik }: any) => {
  const [field, meta] = useField(name ?? 'TextField');
  const [focus, setFocus] = useState<boolean>(false);
  const [showPass, setShowPass] = useState<boolean>(false);
  const {
    label,
    disabled,
    sx,
    autoComplete = 'false',
    autocomplete,
    placeholder,
    variant = 'filled',
    margin,
    fullWidth = true,
    rows,
    size = 'small',
    required,
    autoFocus,
    readOnly,
    maxLength,
    inputComponent,
    inputProps,
    endAdornment,
    toUpperCase,
    onClick,
    InputProps
  } = config;

  // fix: A component is changing an uncontrolled input
  if (!field.value) {
    field.value = config.defaultValue ?? '';
  }
  if (toUpperCase) {
    field.value = field.value.toUpperCase();
  }
  const haveError: boolean = meta.touched && Boolean(meta.error);
  const onBlur = (e: any) => {
    field.onBlur(e);
    setFocus(false);
  };
  const onClickFocus = (e: any) => {
    if (e.target.classList.contains('icon')) {
      return;
    }
    onClick && onClick();
    setFocus(true);
  };
  const props = {
    ...field,
    label,
    onBlur,
    onClick: onClickFocus,
    disabled: disabled,
    required,
    autoFocus,
    variant,
    size,
    inputProps: {
      maxLength,
      autoComplete
    },
    type: showPass ? 'text' : 'password',
    InputProps: {
      ...InputProps,
      inputComponent,
      inputProps,
      endAdornment,
      readOnly
    },
    rows,
    placeholder,
    margin,
    error: haveError && !focus,
    fullWidth,
    sx,
    autoComplete: autocomplete
  };
  return (
    <>
      <TextField
        {...props}
        InputProps={{
          endAdornment: (
            <Icon
              onClick={() => setShowPass(prev => !prev)}
              sx={{ cursor: 'pointer' }}
              icon={showPass ? 'icon-eye-hide' : 'icon-eye-view'}
            />
          )
        }}
      />
      {!focus && meta.error && meta.touched ? (
        <div className="errorMessage">{meta.error}</div>
      ) : null}
    </>
  );
};

export default PasswordField;
