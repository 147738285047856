import { isJsonString } from '@helper/stringFunction';
import { userLevelSelector } from '@user/selector/userInfoSelector';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useOutlet } from 'react-router';

export default function LevelUserProvider({ children }) {
  const userLevel = useSelector(userLevelSelector);
  const outletContext = useOutlet();
  const outletId = get(
    outletContext,
    'props.children.props.match.route.id',
    ''
  );

  if (isJsonString(outletId)) {
    const outletPermission = Object.values<string>(
      JSON.parse(get(outletContext, 'props.children.props.match.route.id', ''))
    )[0]
      .split('-')
      .map(Number);
    if (outletPermission.includes(userLevel)) return children;
    return <div>403</div>;
  }
  return children;
}
