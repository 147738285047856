import Icon from '@common/components/Icon';
import { Box, Grid, Typography } from '@mui/material';
import Footer from '../ActionFooter';
import { Wrapper } from './styled';

export default function ClientInFlightDialog(props: any) {
  const { closeDialog, cancelButtonLabel, errorData } = props;
  const { flight_heads } = errorData;

  return (
    <>
      <Wrapper sx={{ maxWidth: 600 }}>
        <Typography
          variant="body1"
          color="neutral.dark3"
          sx={{ fontWeight: 600 }}
        >
          The client cannot be deleted because they are associated with the
          following flights:
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            {flight_heads.map((item: Record<string, any>, index: number) => {
              return (
                <Grid key={index} item xs={6} md={4}>
                  <Box
                    sx={{
                      backgroundColor: 'neutral.light3',
                      p: 2,
                      borderRadius: '8px',
                      display: 'flex',
                      gap: '4px'
                    }}
                  >
                    <Icon
                      icon="icon-airplane-above-fill"
                      size="md"
                      sx={{ color: 'primary.light1' }}
                    />

                    <Typography variant="subtitle2" color="primary.dark1">
                      {item.flight_code}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Footer
            cancelButtonLabel={cancelButtonLabel}
            closeDialog={closeDialog}
          />
        </Box>
      </Wrapper>
    </>
  );
}
