import Select from '@common/components/Select';
import { FLIGHT_STATUS } from '@constant/flight_status';
import { Checkbox, FormControl, MenuItem, Typography } from '@mui/material';
import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { BpCheckedIcon, BpIcon } from './CheckboxForm';

const FLIGHT_STATUS_OPTIONS = [
  { value: FLIGHT_STATUS.ENQUIRY, label: 'Enquiry' },
  {
    value: FLIGHT_STATUS.AC_SIGNED,
    label: 'A/C signed for, client to be invoiced'
  },
  { value: FLIGHT_STATUS.INVOICE_SENT, label: 'Invoice sent' },
  { value: FLIGHT_STATUS.OPERATOR_PAID, label: 'Operator paid' },
  { value: FLIGHT_STATUS.PAYMENT_RECEIVED, label: 'Payment received' }
];

const FlightStatusForm = ({ config, name }: any) => {
  const [field, , { setValue }] = useField(name);
  const [showError, setShowError] = useState(false);
  const [tempValue, setTempValue] = useState(field?.value ?? [1, 2, 3, 4, 5]);

  useEffect(() => {
    setTempValue(field?.value ?? [1, 2, 3, 4, 5]);
  }, [field?.value]);

  const handleChange = (event: any) => {
    if (event.target.value?.length === 0) {
      setShowError(prev => !prev);
      return;
    }
    setShowError(false);

    setTempValue(event.target.value as string[]);
  };

  return (
    <FormControl fullWidth variant="outlined" size="small">
      <Select
        value={tempValue}
        error={showError}
        multiple
        variant="outlined"
        MenuProps={{
          PaperProps: {
            sx: { maxHeight: 264, boxShadow: '0px 2px 4px 0px #585C613D' }
          },
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }}
        onClose={() => {
          setValue(tempValue.length === 5 ? null : tempValue);
          setShowError(false);
        }}
        onChange={handleChange}
        renderValue={(selected: any) => (
          <>
            {selected.length === 5
              ? 'All status'
              : `${selected.length} selected`}
          </>
        )}
      >
        {FLIGHT_STATUS_OPTIONS?.map((item, index) => {
          return (
            <MenuItem key={index} disableRipple value={item.value}>
              <Checkbox
                size="small"
                checked={tempValue?.indexOf(item.value) > -1}
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
              />
              <Typography variant="body2" color="neutral.dark3">
                {item.label}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
      {showError ? (
        <div className="errorMessage">
          Please select one option or more options.
        </div>
      ) : null}
    </FormControl>
  );
};

export default FlightStatusForm;
