import { Grid, Typography } from '@mui/material';
import {
  AvatarWrapper,
  MenuWrapper,
  TableHeaderWrapper,
  TableWrapper
} from './styled';
const ClientTableHeader = () => {
  return (
    <TableHeaderWrapper>
      <AvatarWrapper></AvatarWrapper>
      <TableWrapper>
        <Grid container>
          <Grid sx={{ pl: 2.5 }} item xs={1} xl={(120 / 1104) * 12}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              ID
            </Typography>
          </Grid>
          <Grid item xs={3} xl={(232 / 1104) * 12}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Name
            </Typography>
          </Grid>
          <Grid item xs={2} xl={(160 / 1104) * 12}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Passport number
            </Typography>
          </Grid>
          <Grid item xs={3} xl={(248 / 1104) * 12}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Company
            </Typography>
          </Grid>
          <Grid item xs={2} xl={(248 / 1104) * 12}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Nationality
            </Typography>
          </Grid>
          <Grid item xs={1} xl={(96 / 1104) * 12}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Status
            </Typography>
          </Grid>
          <Grid item xs={'auto'}>
            <Typography
              color="neutral.dark1"
              variant="subtitle2"
              noWrap
            ></Typography>
          </Grid>
        </Grid>
      </TableWrapper>
      <MenuWrapper></MenuWrapper>
    </TableHeaderWrapper>
  );
};

export default ClientTableHeader;
