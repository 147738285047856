import { Paper, styled } from '@mui/material';

export const ClientInfo = styled(Paper, { name: 'ClientDetailClientInfo' })(
  ({ theme }) => ({
    position: 'relative',
    height: 'fit-content',
    width: '348px',
    zIndex: 2,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    marginBottom: theme.spacing(8),
    boxShadow: '0px 2px 4px 0px #0E50783D'
  })
);

export const ClientName = styled('div', { name: 'ClientDetailClientName' })(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '30px',
    color: theme.palette.neutral.dark3,
    textTransform: 'capitalize'
  })
);

export const SectionTitle = styled('div', { name: 'ClientDetailSectionTitle' })(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22.5px',
    color: theme.palette.neutral.dark3,
    textTransform: 'capitalize'
  })
);

export const EditButtonWrapper = styled('div', { name: 'EditButtonWrapper' })(
  ({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(3),
    zIndex: 4
  })
);
