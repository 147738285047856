import { guessInstance } from '@instance/userInstance';
import handleActionError from '@services/handleActionError';
import { enqueueSnackbar } from 'notistack';
import { Onfido } from 'onfido-sdk-ui';
import { call, put, takeLeading } from 'redux-saga/effects';

function* initOnfido(
  onfidoRef: any,
  token: string,
  applicantId: string,
  setDisabledButton: React.Dispatch<React.SetStateAction<boolean>>,
  userToken: string
): Record<string, any> {
  try {
    const promise = new Promise((resolve, reject) => {
      onfidoRef.current = Onfido.init({
        token: token,
        containerId: 'onfido-mount',
        onComplete: function (data: any) {
          resolve({ success: true });
        },
        onError: function (error) {},
        customUI: {
          colorIcon: '#00223D',
          googleFonts: ['Open Sans'],
          colorBackgroundSurfaceModal: '#ffffff',
          colorBackgroundDocTypeButton: 'transparent',
          colorContentButtonTertiaryText: '#48B1E5',
          colorContentButtonSecondaryText: '#0E5078',
          colorBorderButtonSecondary: '#B7C2CD',
          fontSizeTitle:'5vw',
          fontSizeSubTitle:'0.9em',
          fontSizeBody:'0.9em',
          colorBorderButtonSecondaryHover: '#B7C2CD',
          colorBackgroundLinkHover: 'transparent',
          colorContentLinkTextHover: '#0E5078',
          colorBorderLinkUnderline: 'transparent',
          fontFamilyTitle: "'Open Sans', sans-serif",
          colorBackgroundButtonPrimary: '#E63946',
          colorBorderButtonPrimary: '#E63946',
          colorBackgroundButtonPrimaryHover: '#AB2738',
          colorBorderButtonPrimaryHover: '#AB2738',
          colorBackgroundButtonPrimaryActive: '#E63946',
          colorBorderButtonPrimaryActive: '#E63946',
          colorContentButtonPrimaryText: '#FFE5EF',
          colorBackgroundButtonIcon: '#E63946',
          colorBackgroundButtonCameraHover: '#1D7FB3'
        },
        language: 'en_GB',
        steps: [
          {
            type: 'welcome',
            options: {
              title: 'Verify Identity',
              descriptions: [
                'We will use your passport to verify your identity.',
                'Please include the bottom 2 lines of your passport within the frame'
              ]
            }
          },
          {
            type: 'document',
            options: {
              documentTypes: {
                passport: true,
                driving_licence: false,
                national_identity_card: false
              }
            }
          },
          'face',
          'complete'
        ]
      });
    });

    const { success } = yield promise;
    if (success) {
      yield put({ type: '@loading/show' });
      const response = yield guessInstance.request({
        url: '/onfido/do_check',
        data: { applicantId, token: userToken },
        method: 'POST'
      });
      yield put({ type: '@loading/hide' });
      if (response.data.status === 'success') {
        setDisabledButton(false);
        enqueueSnackbar('Identity verification submitted!', {
          variant: 'success'
        });
      }
    }
  } catch (error) {
    yield handleActionError(error);
  }
}

export function* verifyIdentity(action: any): Record<string, any> {
  const {
    userDetail,
    setDisabledButton,
    cancelToken,
    setLoading,
    onfidoRef,
    token: userToken
  } = action.payload;

  try {
    const formData = new FormData();
    formData.append('firstName', userDetail.firstName);
    formData.append('lastName', userDetail.lastName);

    //submit detail
    setLoading(true);
    const response = yield guessInstance.request({
      url: '/onfido/create_applicant',
      data: { ...userDetail, token: userToken },
      method: 'POST',
      cancelToken
    });

    if (!response?.data?.data?.applicant_id) {
      throw new Error('Applicant not found. Please try again.');
    }
    const applicantId = response.data.data.applicant_id;

    const onfidoResponse = yield guessInstance.request({
      url: '/onfido/get_js_sdk_token',
      data: {
        applicantId,
        token: userToken
      },
      method: 'POST',
      cancelToken
    });

    if (!onfidoResponse?.data?.data?.token) {
      throw new Error('Onfido init failed. Please try again.');
    }
    const token = onfidoResponse.data.data.token;
    setLoading(false);
    yield call(
      initOnfido,
      onfidoRef,
      token,
      applicantId,
      setDisabledButton,
      userToken
    );
  } catch (err) {
    yield handleActionError(err);
  }
}

const identityStepSaga = [
  takeLeading('@register/verifyIdentity', verifyIdentity)
];

export default identityStepSaga;
