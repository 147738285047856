import { styled } from '@mui/material';

export const Wrapper = styled('div', { name: 'ConfirmDialogWrapper' })(
  ({ theme }) => ({
    overflow: 'hidden',
    padding: '24px 32px',
    minWidth: '600px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '300px'
    }
  })
);
