import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { userInfoSelector } from '@user/selector/userInfoSelector';
import { get } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function AirportCodeToggle() {
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoSelector);

  const airportCodeConfig = get(userInfo, 'airport_code_preference', 'local');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (!newValue) return;
    dispatch({
      type: '@user/updateInfo',
      payload: { airport_code_preference: newValue }
    });
  };

  return (
    <ToggleButtonGroup
      value={airportCodeConfig}
      exclusive
      onChange={handleChange}
      fullWidth
    >
      <ToggleButton disableRipple value="iata" aria-label="left aligned">
        <Typography variant="body2">IATA</Typography>
      </ToggleButton>
      <ToggleButton disableRipple value="icao" aria-label="centered">
        <Typography variant="body2">ICAO</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
