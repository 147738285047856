import EmptyFlightTable from './EmptyFlightTable';
import FlightTableItem from './Item';
import FlightTableItemSkeleton from './Item/LoadingSkeleton';
import { FlightListWrapper } from './styled';

export default function FlightTableBlock({ loading, data }) {
  return (
    <FlightListWrapper>
      {loading ? (
        Array(3)
          .fill(0)
          .map((_, index) => <FlightTableItemSkeleton key={index} />)
      ) : data?.data.length ? (
        data?.data.map((item: number, index: number) => (
          <FlightTableItem key={index} id={item} />
        ))
      ) : (
        <EmptyFlightTable />
      )}
    </FlightListWrapper>
  );
}
