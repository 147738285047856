import userLevel from './userConfigMenu.json';

export const getConfig = (level, pathname) => {
  return userLevel.filter(
    item => item.level.includes(level) && !!item.path?.includes(pathname)
  )[0];
};

export const getMenu = (level, isAdmin) => {
  return userLevel.filter(
    item =>
      item.level.includes(level) || (isAdmin && item.level.includes('admin'))
  );
};
