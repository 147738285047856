import { entitySelector } from '@core/selector/entitySelector';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import DirectLink from '@common/components/DirectLink';
import Icon from '@common/components/Icon';
import {
  Avatar,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import router from '@utils/route/router';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AvatarWrapper,
  ClientItemWrapper,
  MenuWrapper,
  TableWrapper
} from './styled';
import { tooltipPassportStatusData } from '@helper/stringFunction';

const placeholder = (
  <Typography variant="body2" color="neutral.main">
    <i>waiting</i>
  </Typography>
);

export default function ClientTableItem({ id }: { id: number }) {
  const data = useSelector(state => entitySelector(state, 'client', id));

  const [open, setOpen] = useState<boolean>(false);
  const anchorPopover = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isEmpty(data)) return null;

  const { firstName, lastName, email, status, deleting } = data;

  const clientName =
    firstName || lastName
      ? `${firstName ?? ''} ${lastName ?? ''}`.trim()
      : email;

  const onDeleteClient = () => {
    handleClose();
    dispatch({
      type: '@clients/deleteClient',
      payload: { id: data.id, clientName }
    });
  };

  const onResendEmail = () => {
    handleClose();
    dispatch({
      type: '@clients/onResendEmail',
      payload: { id: data.id }
    });
  };

  const createFlight = () => {
    router.window.location = `${process.env.REACT_APP_PHP_BASE_URL}/flights.php?new&client=${id}`;
  };

  return (
    <ClientItemWrapper deleting={deleting}>
      <AvatarWrapper>
        <DirectLink to={`/client/${id}`}>
          <Avatar
            className="fs-exclude"
            variant="square"
            sx={{
              width: 64,
              height: 64,
              bgcolor: 'neutral.light2'
            }}
            src={data?.onfido_image}
          />
        </DirectLink>
      </AvatarWrapper>
      <DirectLink to={`/client/${id}`}>
        <TableWrapper>
          <Grid container>
            <Grid sx={{ pl: 2.5 }} item xs={1} xl={(120 / 1104) * 12}>
              <Typography color="neutral.dark1" variant="body2" noWrap>
                {data.id}
              </Typography>
            </Grid>
            <Grid item xs={3} xl={(232 / 1104) * 12}>
              <Typography
                color="neutral.black"
                variant="subtitle3"
                noWrap
                sx={{ pr: 2 }}
              >
                {clientName ?? data?.email}
              </Typography>
            </Grid>
            <Grid item xs={2} xl={(160 / 1104) * 12}>
              <Typography color="neutral.dark3" variant="body2" noWrap>
                {data?.passport_number ?? placeholder}
              </Typography>
            </Grid>
            <Grid item xs={3} xl={(248 / 1104) * 12}>
              <Typography color="neutral.dark3" variant="body2" noWrap>
                {data?.fbo ?? placeholder}
              </Typography>
            </Grid>
            <Grid item xs={2} xl={(248 / 1104) * 12}>
              <Typography color="neutral.dark3" variant="body2" noWrap>
                {data?.nationality?.label ?? placeholder}
              </Typography>
            </Grid>
            <Grid item xs={1} xl={(96 / 1104) * 12}>
              <Tooltip
                title={tooltipPassportStatusData(data.status).text}
                arrow
              >
                <Icon
                  icon={tooltipPassportStatusData(data.status).icon}
                  size="lg"
                  color={tooltipPassportStatusData(data.status).color}
                  haveBackground
                />
              </Tooltip>
            </Grid>
          </Grid>
        </TableWrapper>
      </DirectLink>

      <MenuWrapper ref={anchorPopover} onClick={handleClick}>
        <IconButton
          size="medium"
          sx={{
            p: 1.5,
            m: -1.5,
            ':hover': {
              '.icon': {
                color: 'primary.main',
                transition: 'all 0.2s ease-in-out'
              }
            }
          }}
        >
          <Icon
            icon="icon-dot-menu"
            sx={{ color: 'neutral.dark1' }}
            size="sm"
          />
        </IconButton>
      </MenuWrapper>
      <Menu
        open={Boolean(open)}
        anchorEl={anchorPopover.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ mt: -1.5 }}
      >
        <MenuItem onClick={createFlight} disableRipple>
          <Typography color="neutral.dark3" variant="body2">
            Create flight
          </Typography>
        </MenuItem>
        {status === 1 ? (
          <MenuItem onClick={onResendEmail} disableRipple>
            <Typography color="neutral.dark3" variant="body2">
              Resend activation email
            </Typography>
          </MenuItem>
        ) : null}
        <MenuItem onClick={onDeleteClient} disableRipple>
          <Typography color="danger.main" variant="body2">
            Remove client
          </Typography>
        </MenuItem>
      </Menu>
    </ClientItemWrapper>
  );
}
