import TopBar from '@common/components/Menu/TopBar';
import LevelUserProvider from '@utils/authentication/LevelUserProvider';
import { Outlet } from 'react-router-dom';
import { Main } from './styled';

export default function ListLayout() {

  return (
    <LevelUserProvider>
      <TopBar />
        <Main>
          <Outlet />
        </Main>
    </LevelUserProvider>
  );
}
