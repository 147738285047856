import { Box, styled } from '@mui/material';

export const IdealLegItemWrapper = styled(Box, {
  name: 'IdealLegItemWrapper',
  shouldForwardProp: prop => prop !== 'deleting'
})<{ deleting?: boolean }>(({ theme, deleting }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.neutral.white,
  borderRadius: '4px',
  borderBottom: `1px solid ${theme.palette.neutral.light1}`,
  ...(deleting === true && {
    opacity: 0.3,
    pointerEvents: 'none',
    cursor: 'default'
  })
}));
