import { userInfoSelector } from '@user/selector/userInfoSelector';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

export default function useTimezone() {
  const userInfo = useSelector(userInfoSelector);

  const timezoneConfig = get(userInfo, 'timezone_preference', 'utc');
  return timezoneConfig;
}
