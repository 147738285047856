import { styled } from '@mui/material';

export const Navbar = styled('div', { name: 'NavBar' })(({ theme }) => ({
  height: '100%',
  width: '96px',
  position: 'fixed',
  zIndex: 10,
  top: '0',
  left: '0',
  backgroundColor: theme.palette.primary.dark3,
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '80px',
    flexDirection: 'row',
    gap: theme.spacing(2)
  }
}));

export const NavBarToolWrapper = styled('div', { name: 'NavBarToolWrapper' })(
  ({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      '::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 24,
        right: 0,
        bottom: 0,
        backgroundImage: `url("/images/logo_line_reverse.svg")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center left',
        backgroundSize: 'min(calc(50%), 248px)',
        height: '80px'
      }
    }
  })
);

export const LogoWrapper = styled('div', { name: 'LogoWrapper' })(
  ({ theme }) => ({
    height: '96px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.dark1,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  })
);

export const AvatarWrapper = styled('div', { name: 'AvatarWrapper' })(
  ({ theme }) => ({
    height: '96px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: '80px',
      flexDirection: 'row',
      width: 'fit-content',
      '.MuiAvatar-root': {
        width: 40,
        height: 40,
        fontSize: 18
      }
    }
  })
);

export const NavMenuContainer = styled('div', { name: 'NavMenuContainer' })(
  ({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  })
);

export const NavItem = styled('div', {
  name: 'NavItem',
  shouldForwardProp: prop => prop !== 'active'
})<{ active?: boolean }>(({ theme, active }) => ({
  height: '48px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  alignItems: 'center',
  ':hover': {
    backgroundColor: theme.palette.primary.dark1
  },
  ...(active && {
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderLeft: '5px solid',
    borderColor: theme.palette.primary.light2,
    boxSizing: 'border-box'
  }),
  [theme.breakpoints.down('md')]: {
    height: '100%',
    ...(active && {
      boxShadow: `inset 0px 5px 0px 0px ${theme.palette.primary.light2}`,
      borderLeft: 'none'
    })
  }
}));