import { createSelector } from 'reselect';

const getCoreInfo = (state: any) => state.core;

export const loadingSelector = createSelector(
  getCoreInfo,
  data => data.loading
);

export const bootstrappedSelector = createSelector(
  getCoreInfo,
  data => data.bootstrapped
);
