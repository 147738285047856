import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { theme1 as defaultTheme } from '@utils/theme';
import useGlobalStyles from './globalCss.styles';
import { useGlobal } from '@context/GlobalContext';

type ThemeProviderProps = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: ThemeProviderProps) {
  const global = useGlobal();
  const theme = global?.config?.theme === 'default' ? defaultTheme : {};
  const CssGlobal = () => {
    useGlobalStyles(theme);
    return null;
  };
  return (
    <MuiThemeProvider theme={theme}>
      <CssGlobal />
      {children}
    </MuiThemeProvider>
  );
}
