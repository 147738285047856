import { styled } from '@mui/material';

export const Wrapper = styled('div', {
  name: 'Wrapper',
  shouldForwardProp: prop => prop !== 'step'
})<{ step?: number }>(({ theme, step }) => ({
  position: 'fixed',
  width: '50vw',
  left: '50vw',
  padding: `${theme.spacing(8)} ${theme.spacing(16)} ${theme.spacing(
    8
  )} ${theme.spacing(16)}`,
  [theme.breakpoints.down('xl')]: {
    padding: `${theme.spacing(8)} ${theme.spacing(8)}`
  },
  [theme.breakpoints.down('lg')]: {
    position: 'static',
    width: '100vw',
    ...(step !== 0 && { minHeight: '100svh', height: '100svh' })
  },
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`
  },
  overflow: 'auto',
  top: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.palette.neutral.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'left 0.3s ease-in-out',
  ...(step !== 0 && {
    left: '400px',
    width: 'calc(100vw - 400px)',
    transition: 'left 0.3s ease-in-out'
  })
}));
