import * as React from 'react';
import CircularProgress, {
  CircularProgressProps
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box
      sx={{ position: 'relative', display: { xs: 'inline-flex', lg: 'none' } }}
    >
      <CircularProgress variant="determinate" color="inherit" value={100} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress variant="determinate" {...props} />
      </Box>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          variant="subtitle2"
          component="div"
          color="primary.dark1"
        >{`${props.value / 25}/4`}</Typography>
      </Box>
    </Box>
  );
}

export default function MobileStep({ step }: { step: number }) {
  return <CircularProgressWithLabel value={(step / 4) * 100} />;
}
