import Icon from '@common/components/Icon';
import {
  CheckboxForm,
  SliderForm,
  TextFieldForm,
  PasswordField
} from '@common/formElement';
import { useRegister } from '@context/RegisterContext';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { Content, FooterAction } from './_styled';
import { ConfirmDetailSchema } from '../helper/schema';
import { useRef } from 'react';

export default function ConfirmDetail() {
  const { userDetail, token, setUserDetail, backStep, nextStep } =
    useRegister();
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const handleSubmit = async (value: any, formik: FormikHelpers<any>) => {
    if (!formRef.current.dirty) {
      nextStep();
      return;
    }
    dispatch({
      type: '@register/submitConfirmDetail',
      payload: {
        value,
        token,
        setUserDetail,
        nextStep
      }
    });
  };

  const initialValues = {
    firstName: userDetail.firstName,
    lastName: userDetail.lastName,
    email: userDetail.email,
    password: userDetail.password ?? '',
    confirmPassword: userDetail.confirmPassword ?? '',
    keepData: userDetail.keepData ?? 1,
    consent: userDetail.consent ?? 0
  };

  return (
    <Box sx={{ height: '100%' }} className="flex-column">
      <Typography
        sx={{ mt: { xs: 1, md: 4 } }}
        component="div"
        variant="body2"
        color="neutral.dark3"
      >
        Your details
      </Typography>
      <Box sx={{ mt: 3, flex: 1 }}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={ConfirmDetailSchema}
          validateOnMount
          innerRef={formRef}
        >
          {({ isValid, initialValues }) => (
            <Form
              style={{ height: '100%' }}
              className="flex-space-between-column"
            >
              <Content>
                <Grid
                  container
                  columnSpacing={{ xs: 0, sm: 1 }}
                  rowSpacing={{ xs: 2, sm: 0 }}
                  sx={{ mt: 1, mb: 2 }}
                >
                  <Grid item xs={12} sm={6}>
                    <TextFieldForm
                      name="firstName"
                      config={{
                        label: 'First name',
                        fullWidth: true,
                        required: true
                      }}
                      initialValues={initialValues}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldForm
                      name="lastName"
                      config={{
                        label: 'Last name',
                        fullWidth: true,
                        required: true
                      }}
                      initialValues={initialValues}
                    />
                  </Grid>
                </Grid>
                <TextFieldForm
                  name="email"
                  config={{
                    label: 'Email',
                    fullWidth: true,
                    readOnly: true,
                    required: true,
                    autocomplete: 'email',
                    disabled: true
                  }}
                  initialValues={initialValues}
                />
                <Box sx={{ mt: 2 }}>
                  <Grid
                    container
                    columnSpacing={{ xs: 0, sm: 1 }}
                    rowSpacing={{ xs: 2, sm: 0 }}
                  >
                    <Grid item xs={12} sm={6}>
                      <PasswordField
                        name="password"
                        config={{
                          label: 'New password',
                          fullWidth: true,
                          required: true,
                          autocomplete: 'new-password'
                        }}
                        initialValues={initialValues}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <PasswordField
                        name="confirmPassword"
                        config={{
                          label: 'Confirm password',
                          fullWidth: true,
                          required: true,
                          autocomplete: 'new-password'
                        }}
                        initialValues={initialValues}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Typography
                  variant="body2"
                  component="div"
                  color="neutral.dark3"
                  sx={{ mt: 4, mb: 3 }}
                >
                  How long can we keep your data?
                </Typography>
                <SliderForm name="keepData" initialValues={initialValues} />
                <Box sx={{ mt: 4 }}>
                  <CheckboxForm
                    name="consent"
                    config={{
                      size: 'small',
                      label:
                        'I consent to my usage being tracked to improve AvıoNexus.'
                    }}
                    initialValues={initialValues}
                  />
                </Box>
              </Content>
              <FooterAction>
                <Button
                  onClick={backStep}
                  size="large"
                  variant="outlined"
                  color="inherit"
                  startIcon={<Icon icon="icon-previous-arrow" />}
                >
                  Back to the introduction
                </Button>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  disabled={!isValid}
                  color="primary"
                  endIcon={
                    <Icon
                      icon="icon-previous-arrow"
                      className="icon-flip-x"
                      color="lightAccent"
                    />
                  }
                >
                  Next step
                </Button>
              </FooterAction>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
