import { entitySelector } from '@core/selector/entitySelector';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useFetchDetail(
  module: string,
  apiUrl: string,
  id: number
) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const data = useSelector(state => entitySelector(state, module, id));

  const loadData = () => {
    dispatch({
      type: '@entity/fetchItem',
      payload: {
        module: 'client',
        setLoading,
        apiUrl,
        params: { id: id }
      }
    });
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [data, loading];
}
