import { userInstance } from '@instance/userInstance';
import handleActionError from '@services/handleActionError';
import { debounce, takeEvery } from 'redux-saga/effects';

const cached = {};

export function* getSuggestions(action) {
  const {
    q,
    none,
    apiUrl: url,
    limit = 10,
    initialParams,
    searchable
  } = action.payload;

  const { onSuccess } = action.meta;

  try {
    const key = searchable
      ? none + (q ?? '') + (JSON.stringify(initialParams) ?? '')
      : none;

    if (cached[key]) {
      yield onSuccess({
        loading: false,
        items: cached[key]
      });

      return;
    }

    yield onSuccess({
      loading: true,
      items: []
    });

    const response = yield userInstance.request({
      url,
      method: 'get',
      params: searchable
        ? { q: q || undefined, limit, ...initialParams }
        : undefined
    });

    if (!Array.isArray(response.data.data.data)) {
      onSuccess({
        loading: false,
        items: []
      });
      return;
    }
    const items = Array.isArray(response.data.data.data)
      ? response.data.data.data
      : [];

    cached[key] = items;

    onSuccess &&
      onSuccess({
        loading: false,
        items
      });
  } catch (err) {
    yield handleActionError(err);
    // console.log(err);
  }
}
const suggestSaga = [
  debounce(300, '@suggestion/search', getSuggestions),
  takeEvery('@suggestion/all', getSuggestions)
];
export default suggestSaga;
